import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'

const Loader = ({ children, loaderKey = false, state = false, keys = [] }) => {

    const pageLoader = useSelector(state => state?.utils?.pageLoader)
    keys.forEach((x) => { if (pageLoader[x]) return loaderKey = x })

    return (
        <Spin
        className={` bg-[#34405438] h-full`}
        spinning={pageLoader[loaderKey] || state || false}
        // spinning={true}
        indicator={<LoadingOutlined className='text-[24px] text-custom-primary-700' spin />}
    >
        {children}
    </Spin>
    )
}

export default Loader