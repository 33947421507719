import React, { useEffect } from 'react'
import { FormController } from 'Components/FormControl'
import { useForm } from 'react-hook-form'
import Button from 'Components/Dynamics/Button'
import { Header } from 'Components/Layout.Components'
import Loader from 'Components/Loader/Loader'
import { SettingOutlined } from '@ant-design/icons'
import { Message, Storage } from 'Utility/Services'
import { updatePassword } from 'API/Services'
import { validations } from 'Utility/Validations'
import { utility } from 'Utility'
const Settings = () => {
    const Form = useForm()
    const fields = [
        { type: 1, name: 'name', label: 'Name', rules: { required: false, }, disabled: true, labelCss: 'font-normal text-custom-gray-500 mb-1', placeholder: 'Enter your name', span: 'col-span-12', use: 'antd', className: "text-xs h-12 " },
        { type: 1, name: 'email', label: 'Email', rules: { required: false, }, disabled: true, labelCss: 'font-normal text-custom-gray-500 mb-1', placeholder: 'Enter your email address', span: 'col-span-12', use: 'antd', className: "text-xs h-12 " },
        { type: 7, name: 'old_password', label: 'Old password', labelCss: 'font-normal text-custom-gray-500 mb-1 ', rules: { required: true, }, placeholder: 'Enter your previous password', span: 'col-span-12', use: 'antd', className: "text-xs h-12" },
        { type: 7, name: 'new_password', label: 'New Password', labelCss: 'font-normal text-custom-gray-500 mb-1 ', rules: { required: true, validate: validations?.password }, placeholder: 'Enter your new password', span: 'col-span-12', use: 'antd', className: "text-xs h-12" },
        { type: 7, name: 'confirm_new_password', label: 'Confirm New Password', labelCss: 'font-normal text-custom-gray-500 mb-1 ', rules: { required: true, validate: validations?.password }, placeholder: 'Confirm new password', span: 'col-span-12', use: 'antd', className: "text-xs h-12" },
    ]
    const handleSubmit = async (val) => {
        delete val.name
        delete val.email
        if (val?.new_password !== val?.confirm_new_password) Message?.error("Confirm new password doesn't match new password!")
        else {
            Form?.setValue('old_password', '')
            Form?.setValue('new_password', '')
            Form?.setValue('confirm_new_password', '')
            await updatePassword(val)
        }
    }
    useEffect(() => {
        const user = Storage?.get('user')
        Form?.setValue('name', user?.name)
        Form?.setValue('email', user?.email_address)

    }, [])
    return (
        <Loader keys={['updatePassword']}>
            <div className='bg-[white] p-4 rounded-md'>
                <Header heading={<div>Settings <SettingOutlined /></div>} />
                <div className=' grid grid-cols-12'>
                    <div className='col-span-8'>
                        <FormController
                            formType={'antd'}
                            Form={Form}
                            onSubmit={handleSubmit}
                            className="flex flex-col gap-10"
                            submitButton={
                                <div className='flex justify-end gap-2'>
                                    <Button buttonType='blue' htmlType='submit' additionalClassName='font-semibold text-[13px]'>Update </Button>
                                </div>
                            }
                            fields={fields} />
                    </div>
                </div>
            </div >
        </Loader>
    )
}
export default Settings