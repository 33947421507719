import React from 'react';
import { Button, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { Message } from 'Utility/Services';
import { checkUploadedFileDoc, checkUploadedImage, debounce } from 'Utility';


const UploadButton = ({ button, setImage = () => { }, fileType }) => {
    const onChange = debounce(async (data) => {
        const { file } = data
        var fileCheck
        if (fileType === 'file') { fileCheck = fileCheck = checkUploadedFileDoc(file?.originFileObj) }
        else { fileCheck = checkUploadedImage(file?.originFileObj) }
        if (fileCheck) {
            Message.success('File is uploading!')
            setImage(file)
        }
        return
    })
    return <Upload showUploadList={false} action={false} onChange={onChange}>
        {button ? button : <Button className='flex gap-2 items-center'> <UploadOutlined />Click to Upload</Button>
        }
    </Upload>
}

export default UploadButton;