import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import UnderMaintenance from './UnderMaintenance'

const MaintenanceRouter = () => {

    return (
        <BrowserRouter>
            <Routes>
                {/* Default redirect to Maintenance Page */}
                <Route path="*" element={<UnderMaintenance />} />
            </Routes>
        </BrowserRouter>
    )
}

export default MaintenanceRouter