import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    currentUser: null,
    signUp: null
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {

        /* Used For Login and Logout ( Pass the User Obj when logging in and pass null when logging out ) */
        updateUser: (state, action) => {
            state.currentUser = action.payload;
        },
        /* Used For SignUp Process. Varies project wise */
        signUp: (state, action) => {
            let { step } = action.payload;
            switch (step) {
                case 1:
                    state.signUp.credentials = action.payload
                    break;
                case 2:
                    state.signUp.dob = action.payload
                    break;
                default:
                    state.signUp.phoneNumber = action.payload
            }
        },
        /* Clear Auth Structure */
        clearAuth: (state, action) => {
            state = initialState
        },
        /* Update Token w.r.t the process */
        updateToken: (state, action) => {
            let { token, tokenType = 'accessToken', obj = 'currentUser' } = action.payload;
            state[obj][tokenType] = token;
        },

    },
})

// Action creators are generated for each case reducer function
export const { updateUser, updateToken } = authSlice.actions

export default authSlice.reducer