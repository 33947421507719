import React, { useEffect } from 'react'
import { FormController } from 'Components/FormControl'
import { useForm } from 'react-hook-form'
import Button from 'Components/Dynamics/Button'
import { Header } from 'Components/Layout.Components'
import Loader from 'Components/Loader/Loader'
import { VideoCameraAddOutlined } from '@ant-design/icons'

import { useParams } from 'react-router-dom'
import { addTutorial } from 'API/Services/test.service'
const AppTutorial = () => {
    const params = useParams()
    const { id } = params
    const Form = useForm()
    const fields = [
        {
            type: 1, name: 'url',
            label: 'Enter Tutorial Link',
            rules: { required: true, },
            disabled: false,
            labelCss: 'font-normal text-custom-gray-500 mb-1',
            placeholder: 'Enter Tutorial Link ',
            span: 'col-span-12', use: 'antd', className: "text-xs h-12 "
        },
    ]
    const handleSubmit = async (val) => {
        const result = await addTutorial({
            name: 'App Tutorial',
            order: 1,
            type: "VIDEO",
            url: val?.url
        })
        console.log({result})
    }
    // useEffect(() => {
    //     if (id) {

    //     }
    // }, [])
    return (
        <Loader keys={['addTutorial']}>
            <div className='bg-[white] p-4 rounded-md'>
                <Header heading={<div className='flex gap-3 items-center'>How To Use App <VideoCameraAddOutlined /></div>} />
                <div className=' grid grid-cols-12 mt-4'>
                    <div className='col-span-8'>
                        <FormController
                            formType={'antd'}
                            Form={Form}
                            onSubmit={handleSubmit}
                            className="flex flex-col gap-10"
                            submitButton={
                                <div className='flex justify-end gap-2'>
                                    <Button buttonType='blue' htmlType='submit' additionalClassName='font-semibold text-[13px]'> {id ? "Update" : "Create"}</Button>
                                </div>
                            }
                            fields={fields} />
                    </div>
                </div>
            </div >
        </Loader>
    )
}
export default AppTutorial