import React, { useState } from 'react'
import { Header } from '../../../Layout.Components'
import { Divider } from 'antd'
import DynamicTable from '../../../Layout.Components/Custom/DynamicTable'
import { getColumn } from '../../../../Utility/Columns'
import Loader from 'Components/Loader/Loader'
import { FormController } from 'Components/FormControl'
import { useForm } from 'react-hook-form'
import Button from 'Components/Dynamics/Button'
import Image from 'Components/Dynamics/Image'
import UploadButton from 'Components/Micro/UploadButton/UploadButton'
import { ImageImports } from 'Assets/Images'
import { useLocation, useParams } from 'react-router-dom'

const CreateCourse = () => {
  const { id } = useParams()
  const location = useLocation()

  const Form = useForm()
  const [file, setFile] = useState('')
  const handleFile = async (file, type) => {

  }
  const fields = [
    { type: 1, divClassName: 'flex justify-between items-center', divider: false, name: 'name', label: 'Name', rules: { required: false }, placeholder: '', use: 'antd', span: "", onChange: (value) => { }, className: "w-[100%] text-[11px] h-[32px]" },
    { type: 1, divClassName: 'flex justify-between items-center', divider: false, name: 'emailAddress', label: 'Email Address', rules: { required: false }, placeholder: '', use: 'antd', span: "", onChange: (value) => { }, className: "w-[100%] text-[11px] h-[32px]" },
    { type: 7, divClassName: 'flex justify-between items-center', divider: false, name: 'password', typeOf: "Number", label: 'Password', rules: { required: false }, placeholder: '', use: 'antd', span: "", onChange: (value) => { }, className: "w-[100%] text-[11px] h-[32px]" },
    { type: 7, divClassName: 'flex justify-between items-center', divider: true, name: 'confirmPassword', typeOf: "Number", label: 'Confirm Password', rules: { required: false }, placeholder: '', use: 'antd', span: "", onChange: (value) => { }, className: "w-[100%] text-[11px] h-[32px]" },
  ]
  return (
    <Loader keys={['']}>
      <div className='bg-[white] p-4 rounded-md'>
        <Header heading={'Create Course'} />
        <div className=' grid grid-cols-12'>
          <div className='col-span-8'>
            <div className='flex justify-between  items-center my-4'>
              <div className='flex flex-col '>
                <p className='font-bold text-[11px]'>{'Picture'}</p>
                <p className='text-custom-gray-500 text-[10px]'>{'This will be displayed on your profile.'}</p>
              </div>
              <div className='flex justify-between w-[50%]  items-center'>
                <Image src={file || ImageImports?.Avatar} className={'rounded-full object-contain'} width={60} />
                <div className='flex gap-3  w-'>
                  <Button onClick={() => setFile('')} additionalClassName='shadow-none text-custom-gray-500 border-none text-[11px] p-0 font-bold'>{'Delete'}</Button>
                  <UploadButton fileType={'image'} setImage={(file) => handleFile(file, 'image')} button={<Button additionalClassName='shadow-none text-custom-primary-700 border-none text-[11px] font-bold p-0'>{'Update'}</Button>} />
                </div>
              </div>
            </div>

            <FormController
              formType={'antd'}
              Form={Form}
              onSubmit={(val) => console.log({ val })}
              className="flex flex-col gap-10"
              submitButton={
                <div className='flex justify-end'>
                  <Button buttonType='blue' htmlType='submit' additionalClassName='font-semibold text-[13px]'>Create User</Button>
                </div>
              }
              fields={fields} />
          </div>
        </div>
      </div >
    </Loader>

  )
}

export default CreateCourse