import React from 'react'
import { BellOutlined, CloseOutlined, MenuOutlined, SettingTwoTone, UserOutlined } from "@ant-design/icons";
import Button from 'Components/Dynamics/Button';
import { utility } from 'Utility';
import { pageRoutes } from 'Routing/page.routes';
const DashboardHeader = ({ setHamburgerMenu, hamburgerMenu }) => {
    return (
        <div className='flex justify-between items-center bg-[white] h-[full] '>
            <div className='flex flex-col gap-2'>
                <h1 className='font-semibold'>Admin Dashboard</h1>
                <h1 className='text-[11px]'>Welcome Back Tony!</h1>

            </div>
            <div className='flex gap-3'>
                <Button onClick={() => utility?.navigate(pageRoutes?.dashboard?.settings)} additionalClassName='border-none shadow-none text-[14px]'>
                    <UserOutlined className='cursor-pointer' /> Admin
                </Button>
            </div>
        </div>
    )
}
export default DashboardHeader