import React from 'react'

const Header = (props) => {
  const { heading, children } = props
  return (
    <div className='flex justify-between items-center'>
      <span className='font-bold text-lg '>
        {heading}
      </span>
      {children}
    </div>
  )
}

export default Header