
import { catchAsyncErrors } from 'API/Handlers';
import { deleteRequest, getRequest, postRequest, putRequest } from 'API/api.methods';
import { apiRoutes } from 'API/api.routes';
import { setPageLoader, verification } from 'Redux/Features';
import { pageRoutes } from 'Routing/page.routes';
import { addQueryParams, utility } from 'Utility';
import { Message, Storage } from 'Utility/Services';
const getOfficers = catchAsyncErrors(async (query) => {
    utility.dispatch(setPageLoader({ page: 'getOfficers', flag: true }))
    let result = await getRequest({
        endPoint: addQueryParams(apiRoutes?.immigrationOfficer?.getOfficer, query),
    })
    utility.dispatch(setPageLoader({ page: 'getOfficers', flag: false }))
    return result
})
const addImmigrationOfficer = catchAsyncErrors(async (body) => {
    utility.dispatch(setPageLoader({ page: 'addImmigrationOfficer', flag: true }))
    let result = await postRequest({
        endPoint: addQueryParams(apiRoutes?.immigrationOfficer?.addImmigrationOfficer,),
        body: body
    })
    utility.dispatch(setPageLoader({ page: 'addImmigrationOfficer', flag: false }))
    Message?.success(result?.Message)
    return result
})
const updateImmigrationOfficer = catchAsyncErrors(async (id, body) => {
    utility.dispatch(setPageLoader({ page: 'updateImmigrationOfficer', flag: true }))
    let result = await putRequest({
        endPoint: apiRoutes?.immigrationOfficer?.updateImmigrationOfficer + id,
        body: body
    })
    utility.dispatch(setPageLoader({ page: 'updateImmigrationOfficer', flag: false }))
    Message?.success(result?.Message)
    return result
})
const deleteOfficer = catchAsyncErrors(async (id, body) => {
    utility.dispatch(setPageLoader({ page: 'deleteOfficer', flag: true }))
    let result = await deleteRequest({
        endPoint: apiRoutes?.immigrationOfficer?.deleteOfficer + id,
    })
    utility.dispatch(setPageLoader({ page: 'deleteOfficer', flag: false }))
    Message?.success(result?.Message)
    return result
})

export { deleteOfficer, updateImmigrationOfficer, addImmigrationOfficer, getOfficers, }