import socketIoClient from "socket.io-client"
import { socketsURL } from "../Environments/environment"

// const socketInstance = socketIoClient(socketsURL)
let wsInstance = new WebSocket(socketsURL)


// socketInstance.on('connect', () => {
//     console.log('connected to realtime server...')
// });

// const disconnect = () => {
//     socketInstance.emit('leave', socketId)
//     socketInstance.off(socketId)
// }

export {
    wsInstance as socket,
    // disconnect,
}