import { LocalStorage } from "./LocalStorage"
import { Messages } from "./Message"
import { Notifications } from "./Notification"

export * from "./LocalStorage"
export * from "./PreReqs"

/* Instances for Notifcations/Messages Classes */
const Notify = new Notifications()
const Message = new Messages()
const Storage = new LocalStorage()

export {
    Notify,
    Message,
    Storage
}