import { Button as AntdButton } from 'antd'
import React from 'react'
import { ImageImports } from '../../../Assets/Images';
import Image from '../Image';


const Button = ({ buttonType = "default", className = "", ...props }) => {
    const ButtonMap = {
        default: <DefaultButton className={`text-[10px] ${props?.additionalClassName}`}
            {...props} />,
        blue: <DefaultButton className={` bg-custom-primary-500 text-white text-[11px] ${props?.additionalClassName}`}
            {...props} />,
        lightBlue: <DefaultButton className={` bg-[#e4ebfd] text-custom-primary-500  text-[11px] ${props?.additionalClassName}`}
            {...props} />,
        red: <DefaultButton className={` bg-custom-error-50 text-custom-error-700 text-[11px] ${props?.additionalClassName}`}
            {...props} />,
        darkRed: <DefaultButton className={` bg-custom-error-600 text-[white] text-[11px] ${props?.additionalClassName}`}
            {...props} />,
        lightGreen: <DefaultButton className={`bg-custom-primary-50 text-custom-primary-700 text-[11px] ${props?.additionalClassName}`}
            {...props} />,

    }

    return ButtonMap[buttonType]

}

export default Button

const DefaultButton = ({ gap = "gap-1", ...props }) => {

    return (
        <AntdButton
            prefix={<Image src={ImageImports?.LongArrowRight} />}
            onClick={props?.onClick}
            className={props?.className}
            {...props}
        >
            <div className={`flex items-center justify-center ${gap}`}>
                {props?.btnIcon && props?.btnIcon} {props?.children} {props?.btnIconEnd && props?.btnIconEnd}
            </div>
        </AntdButton>
    )
}

