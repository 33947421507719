import { VideoCameraOutlined } from '@ant-design/icons'
import { getAllCourses } from 'API/Services/courses.service'
import pagination from 'Components/HighOrderFunctions/Pagination'
import DynamicTable from 'Components/Layout.Components/Custom/DynamicTable'
import Loader from 'Components/Loader/Loader'
import { getColumn } from 'Utility/Columns'
import React, { useEffect, useState } from 'react'

const PopularVideos = pagination((props) => {
    const { currentPage, setTotal, setCurrentPage } = props
    const [data, setData] = useState([])
    const fetchCourses = async () => {
        const result = await getAllCourses()
        setData(result)
    }
    useEffect(() => {
        fetchCourses()
    }, [])
    return (
        <div className='w-full bg-white p-3 rounded-md '>
            <p className='font-semibold text-[22px] my-2 flex gap-3'>Popular Courses <VideoCameraOutlined /> </p>
            <Loader keys={['getAllCourses']}>
                <DynamicTable column={getColumn('adminDashboardVideos')} pagination={false} data={data}
                    className={`border border-custom-gray-200  rounded-md mt-3`} />
            </Loader>
        </div>
    )
})

export default PopularVideos