import { IS_UNDER_MAINTENANCE, baseURL, socketsURL } from "./environment";

const appConfig = {
    apiURL: baseURL,
    socketURL: socketsURL,
    underMaintenance: IS_UNDER_MAINTENANCE
}

export {
    appConfig,
}