import { utility } from "../../Utility";
import { LocalStorage } from "../../Utility/Services"
const Storage = new LocalStorage()

export const responseHandler = async (res) => {
    let { status } = res;
    res = await res.json()
    if (status === 200)
        return res
    if ([403, 401].includes(status)) {
        Storage.clear()
        utility.navigate('/')
    }
    throw new Error(res?.detail || res?.error || res.Message, { cause: res.status })
}