import { PlusCircleOutlined } from '@ant-design/icons'
import { deleteQuestion, deleteQuiz, getQuizById } from 'API/Services/quiz.service'
import Button from 'Components/Dynamics/Button'
import CustomModal from 'Components/Dynamics/Modals'
import { Header } from 'Components/Layout.Components'
import DynamicTable from 'Components/Layout.Components/Custom/DynamicTable'
import Loader from 'Components/Loader/Loader'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { pageRoutes } from 'Routing/page.routes'
import { utility } from 'Utility'
import { getColumn } from 'Utility/Columns'
import McqQuiz from './McqQuiz'

const ManageQuiz = () => {
    const { id } = useParams()
    const [data, setData] = useState([])
    const [modal, setModal] = useState({
        delete: false,
        add: false
    })
    const [modalData, setModalData] = useState({})
    const onDelete = async () => {
        const result = await deleteQuestion(modalData?.id)
        if (data?.Questions?.length == 1) await deleteQuiz(data?.id)
        await getQuiz()
        handleModal('delete')

    }
    const handleModal = (name, info = false,) => {
        setModal((prev) => { return { ...prev, [name]: !modal[name] } })
        setModalData(info)

    }
    const getQuiz = async () => {
        const result = await getQuizById(id)
        setData(result)
    }

    useEffect(() => {
        if (id) getQuiz()
    }, [id])
    return (
        <form onSubmit={() => { }}>

            <Loader keys={['getQuizById', 'deleteQuiz', 'deleteQuestion','updateQuizQuestion']}>
                <Header heading={'Manage Quiz'} >
                    <Button onClick={() => { handleModal('add') }} buttonType='blue'>
                        <PlusCircleOutlined />  {data?.Questions?.length ? 'Add Question' : 'Create Quiz'}
                    </Button>
                </Header>
                <DynamicTable column={getColumn('quizListing', handleModal)} pagination={false} data={data?.Questions}
                    className={`border border-custom-gray-200  rounded-md mt-3`} />

                <CustomModal
                    stateReason={''}
                    width={500}
                    showTitleIcon={false}
                    modalTitle={modalData ? 'Edit Question' : data?.Questions?.length ? 'Add Question' : " Create Quiz"}
                    open={modal?.add}
                    close={() => handleModal('add')}
                    field={<div className='w-full mt-5'>
                        <McqQuiz
                            modal={modal?.add}
                            modalData={modalData}
                            getQuiz={getQuiz}
                            data={modalData}
                            quizId={data?.id}
                            close={() => { handleModal('add') }}
                            createQuizCheck={data?.Questions?.length ? false : true} />
                    </div>}
                />
                <CustomModal
                    stateReason={'Are you sure you want to delete this question?'}
                    modalTitle='Delete'
                    open={modal?.delete}
                    close={() => handleModal('delete')}
                    field={<div className='w-full mt-5'>
                        <Button onClick={onDelete} additionalClassName='w-full' buttonType='darkRed'>
                            Delete
                        </Button>
                    </div>}
                />
            </Loader>
        </form>
    )
}

export default ManageQuiz