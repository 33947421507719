import React, { useState } from 'react'
import "./styles.css"
const ErrorProvider = ({ message, status, ErrorComp, ...props }) => {
    if (!status)
        return props?.children
    if (ErrorComp)
        return ErrorComp

    return (
        <>
            {props?.children}
            <span className={`text-[10px] text-red-600 ${props?.errorPosition || " "}  `}>{message}</span>
        </>
    )
}
export default ErrorProvider