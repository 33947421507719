import { catchAsyncErrors } from 'API/Handlers';
import { uploadRequest } from 'API/api.methods';
import { apiRoutes } from 'API/api.routes';
import { setPageLoader, verification } from 'Redux/Features';
import { addQueryParams, utility } from 'Utility';

export const uploadApi = catchAsyncErrors(async (body, route_type = 1) => {
    const formData = new FormData();
    formData?.append('route_type', route_type)
    body?.forEach(({ originFileObj }) => formData?.append("file", originFileObj))
    utility?.dispatch(setPageLoader({ page: 'uploadApi', flag: true }))
    let response = await uploadRequest({
        endPoint: addQueryParams(apiRoutes?.generic?.upload),
        body: formData,
    })
    utility.dispatch(setPageLoader({ page: 'uploadApi', flag: false }))
    return response?.data?.URL
})