import React, { useState } from 'react'
import { DashboardHeader, Sidebar } from '../../Layout.Components'

const DashboardLayout = ({ children }) => {

    const [hamburgerMenu, setHamburgerMenu] = useState(true)
    const sidebarWidth = '260px'
    const headerHeight = '90px'
    return (
        <div className='w-[100%] overflow-hidden min-h-[100vh]'>
            {/* Header */}
            <div className={`ml-${sidebarWidth}] w-[100%] p-6 pt-8 z-50	 custom-dashboar-header box-border fixed border border-custom-gray-50 bg-[white]`}
                style={{
                    left: hamburgerMenu ? sidebarWidth : "0px",
                    width: hamburgerMenu ? `calc(100% - ${sidebarWidth})` : "100%",
                    transition: '0.2s ease-in',
                    height: headerHeight,
                    borderBottom:"1px solid #EAECF0"
                }}>
                <DashboardHeader setHamburgerMenu={setHamburgerMenu} hamburgerMenu={hamburgerMenu} />
            </div>
            {/* Sidebar */}
            <div className={`fixed top-0 left-0 bg-[#aaaaaa] h-[100vh] box-border sideBar overflow-hidden`}
                style={{
                    width: hamburgerMenu ? sidebarWidth : '0px',
                    transition: '0.2s ease-in'
                }}>
                <div>
                    <Sidebar />
                </div>
            </div>
            {/* Content */}
            <div className={`box-border h-[100%]  `}
                style={{
                    marginLeft: hamburgerMenu ? sidebarWidth : "0px",
                    width: hamburgerMenu ? `calc(100% - ${sidebarWidth})` : "100%",
                    transition: '0.2s ease-in',
                    marginTop: headerHeight

                }}>
                <div className=' w-100% p-4 bg-custom-gray-50 h-full'>
                    {children}
                </div>
            </div>
        </div >
    )
}

export default DashboardLayout