import { BellOutlined, NotificationOutlined } from '@ant-design/icons'
import { getAllNotifications, markAllRead } from 'API/Services/notification.service'
import Button from 'Components/Dynamics/Button'
import CheckEmpty from 'Components/Empty'
import pagination from 'Components/HighOrderFunctions/Pagination'
import DynamicCard from 'Components/Layout.Components/Custom/DynamicCard'
import { Divider } from 'antd'
import React, { useEffect, useState } from 'react'

const SidebarNotification = pagination((props) => {
    const { currentPage, setTotal, setCurrentPage, setpageSize } = props
    setpageSize(5)
    const [data, setData] = useState([])
    console.log("🚀 ~ SidebarNotification ~ data:", data)
    const getNotification = async () => {
        const result = await getAllNotifications(
            {
                limit: 5,
                offset: currentPage - 1
            }
        )
        setTotal(result?.count)
        setData(result?.Notifications)
    }
    useEffect(() => {
        getNotification()
    }, [currentPage])
    return (
        <div>
            <div className=' flex flex-col gap-3  bg-[white] p-3 rounded-md'>
                <div className='flex justify-between item-center'>
                    <p className='font-semibold text-[22px] flex  gap-2'>Notifications <BellOutlined /></p>
                    <Button onClick={async () => { await markAllRead(); await getNotification() }} additionalClassName='bg-custom-blue-50 text-custom-blue-700 font-[600]'  >See all notification</Button>
                </div>
                <Divider className='my-2' />
                <CheckEmpty arr={data?.length}>
                    {
                        data?.map((item, index) => <DynamicCard data={item} mainDivCss='w-full ' cardType='notificationsListing' />)
                    }
                </CheckEmpty>
            </div>
        </div>
    )
})

export default SidebarNotification