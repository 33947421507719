
import { BarChartOutlined, BellOutlined, DollarOutlined, FormOutlined, HomeOutlined, MessageOutlined, SnippetsOutlined, UsergroupAddOutlined, UserOutlined, VideoCameraOutlined } from "@ant-design/icons";
import { getItem } from "./helpers";
import { ImageImports } from "../Assets/Images";
import Image from "../Components/Dynamics/Image";
import { pageRoutes } from "../Routing/page.routes";
export const sidebarItems = {

    admin: [
        getItem('2', 'Home', pageRoutes?.dashboard?.home, <HomeOutlined />,),
        getItem('9', 'Immigration lawyers', pageRoutes.dashboard?.Lawyer?.lawyerListing, <UsergroupAddOutlined />,),

        getItem('2', 'App Tutorial', pageRoutes?.dashboard?.howToUseApp, <VideoCameraOutlined />,),
        getItem('6', 'Announcement', '/chat', <BellOutlined />,
            [
                getItem("0", 'Announcements', pageRoutes?.dashboard?.announcement?.allAnnouncement, ''),
                getItem("0", 'Create Announcement', pageRoutes?.dashboard?.announcement?.createAnnouncement, ''),
                // getItem("0", 'Videos', 'Dashboard', ''),
            ]),

        getItem('1', 'Courses', pageRoutes?.dashboard?.Courses?.courseListing, <SnippetsOutlined />,),
        getItem('2', 'Test', '/quiz', <BarChartOutlined />,
            [
                getItem("2.2", 'All Test', pageRoutes?.dashboard?.Tests?.testListing, ''),
                getItem("2.1", 'Create  Test', pageRoutes?.dashboard?.Tests?.createTest, ''),
            ]
        ),
        getItem('3', 'N400', '/n400', <FormOutlined />),
        getItem('3', 'Users', '/users', <UserOutlined />,),
        getItem('4', 'Payments', pageRoutes?.dashboard?.Payment?.payments, <DollarOutlined />,),
        getItem('5', 'Chat', pageRoutes?.dashboard?.chat?.chat, <MessageOutlined />,),

    ],



};

export const allowedImageTypes = ['image/png', 'image/jpg', 'image/gif', 'image/jpeg']
export const allowedFileTypes = ['file/pdf', 'file/csv', 'file/docx']
export const selectQuestionType = [
    { value: 'MCQ', title: 'Multiple choice' },
    { value: 'TEXT', title: 'Short Answer' },
    // { value: '3', title: 'Multiple choice with image' },
]