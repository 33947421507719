import { initializeApp } from 'firebase/app';
import { getMessaging } from "firebase/messaging";

const firebaseApp = initializeApp({
    apiKey: "AIzaSyBgAY1urAWL3QmHNvhB7xos6BTATd9d6-Q",
    authDomain: "citizenship-usa-7e486.firebaseapp.com",
    projectId: "citizenship-usa-7e486",
    storageBucket: "citizenship-usa-7e486.appspot.com",
    messagingSenderId: "80735948942",
    appId: "1:80735948942:web:a613f26c6d8ccccf21f9df",
    measurementId: "G-VZNTL5XFNP"

});

const messaging = getMessaging(firebaseApp) || '';
const firebase = { app: firebaseApp, messaging }
export default firebase
