import { onMessage } from 'firebase/messaging';
import { Message, preReqs } from '../../Utility/Services'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import firebase from 'Libraries/firebase/firebase';
const ReqProvider = (props) => {
    /* Adding Pre-Reqs to Utility Obj */
    onMessage(firebase?.messaging, ({ notification }) => { Message?.success(notification?.body) });
    preReqs(useDispatch, useNavigate)
    return (props.children)
}
export default ReqProvider