import { Table } from "antd"
import { useState } from "react"

const DynamicTable = ({ tableType = 'default', ...props }) => {
    const dynamicTable = {
        default: <DefaultTable {...props} />
    }
    return dynamicTable[tableType]
}
export default DynamicTable



const DefaultTable = ({ customPagination = true, pagination = false, ...props }) => {

    return <div className="flex flex-col">
        <Table className={`table-style-css  ${props?.className}`} onRow={(record, rowIndex) => {
            return {
                onClick: event => { console.log(record); }
            }
        }} columns={props?.column} dataSource={props?.data} onChange={(page) => { console.log(page); props?.pageChange({ limit: page?.pageSize, page: page?.current }) }} pagination={pagination && { defaultPageSize: 2, pageSizeOptions: ["10", "20", "25", "30", '50', '100'], showSizeChanger: false, }}
        />
    </div>
}