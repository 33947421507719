
import { catchAsyncErrors } from 'API/Handlers';
import { getRequest, postRequest, putRequest } from 'API/api.methods';
import { apiRoutes } from 'API/api.routes';
import { setPageLoader, verification } from 'Redux/Features';
import { pageRoutes } from 'Routing/page.routes';
import { addQueryParams, utility } from 'Utility';
import { Message, Storage } from 'Utility/Services';
const getAllAnnouncement = catchAsyncErrors(async (body) => {
    utility.dispatch(setPageLoader({ page: 'getAllAnnouncement', flag: true }))
    let result = await putRequest({
        endPoint: apiRoutes?.announcement?.getAll,
        body: body
    })
    utility.dispatch(setPageLoader({ page: 'getAllAnnouncement', flag: false }))
    return result?.Announcements
})
const getAnnouncementById = catchAsyncErrors(async (id, query) => {
    utility.dispatch(setPageLoader({ page: 'getAnnouncementById', flag: true }))
    let result = await getRequest({
        endPoint: addQueryParams(`${apiRoutes?.announcement?.getById}${id}`, query),
    })

    utility.dispatch(setPageLoader({ page: 'getAnnouncementById', flag: false }))
    return result?.Announcement
})
const createAnnouncement = catchAsyncErrors(async (body) => {
    utility.dispatch(setPageLoader({ page: 'createAnnouncement', flag: true }))
    let result = await postRequest({
        endPoint: apiRoutes?.announcement?.create,
        body: body
    })
    if (result?.success) {
        Message?.success(result?.Message)
        utility?.navigate(pageRoutes?.dashboard?.announcement?.allAnnouncement)
    }
    utility.dispatch(setPageLoader({ page: 'createAnnouncement', flag: false }))
    return result
})
export { getAllAnnouncement, getAnnouncementById, createAnnouncement }