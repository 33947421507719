import React from 'react'

const ErrorPage = () => {

    const pageStyles = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        minHeight: "100vh"
    }
    
    return (
        <div
            style={pageStyles}>
            <h1>Something Went Wrong.</h1>
            <h3>Consider refreshing!</h3>
        </div>
    )
}

export default ErrorPage