import React, { useEffect, useState } from 'react'
import { Header } from '../../../Layout.Components'
import { Divider, Modal } from 'antd'
import DynamicTable from '../../../Layout.Components/Custom/DynamicTable'
import { getColumn } from '../../../../Utility/Columns'
import { useForm } from 'react-hook-form'
import pagination from 'Components/HighOrderFunctions/Pagination'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import { FormController } from 'Components/FormControl'
import { getAllUsers } from 'API/Services/users.service'
import Loader from 'Components/Loader/Loader'
import Button from 'Components/Dynamics/Button'
import CustomModal from 'Components/Dynamics/Modals'
import { ImageImports } from 'Assets/Images'
import UploadButton from 'Components/UploadButton/UploadButton'
import { uploadApi } from 'API/Services/generic.service'
import { Message } from 'Utility/Services'
import { addImmigrationOfficer, deleteOfficer, getOfficers, updateImmigrationOfficer } from 'API/Services/ImmigrationOfficer.service'
import { validations } from 'Utility/Validations'

const LawyerListing = pagination((props) => {
    const { currentPage, setTotal, setCurrentPage } = props
    const Form = useForm()
    const Form2 = useForm()
    const [image, setImage] = useState('')
    const [invoiceImage, setInvoiceImage] = useState('')
    const [modal, setModal] = useState({
        add: false,
        delete: false
    })
    const [modalData, setModalData] = useState({})
    const [data, setData] = useState([])
    const [queryFilters, setQueryFilters] = useState({
        search: '',
        date: "",
        userType: ""
    })
    const fields = [
        { type: 10, name: 'search', state: setQueryFilters, stateKey: "search", userOnChange: () => { setCurrentPage(1) }, rules: { required: false }, label: 'Search By Name', placeholder: 'Search by lawyer name', use: 'antd', span: "col-span-3", className: "h-[40px]", icon: <SearchOutlined /> },
        { type: 10, name: 'lawyerState', state: setQueryFilters, stateKey: "lawyerState", userOnChange: () => { setCurrentPage(1) }, rules: { required: false }, label: 'Search By State', placeholder: 'Search by lawyer state', use: 'antd', span: "col-span-3", className: "h-[40px]", icon: <SearchOutlined /> },
        { type: 10, name: 'lawyerId', state: setQueryFilters, stateKey: "lawyerId", userOnChange: () => { setCurrentPage(1) }, rules: { required: false }, label: 'Search By ID', placeholder: 'Search by lawyer ID', use: 'antd', span: "col-span-3", className: "h-[40px]", icon: <SearchOutlined /> },
    ]
    const fetchOfficers = async () => {
        const result = await getOfficers({
            name: queryFilters?.search,
            date: queryFilters?.date,
            tyoe: queryFilters?.type,
            offset: currentPage - 1,
            limit: 10
        })
        setData(result?.Officers)
        setTotal(result?.count)
    }
    const handleModal = (type) => {
        setModal((prev) => { return { ...prev, [type]: !modal[type] } })
    }
    const modalFields = [
        { type: 1, name: 'name', label: 'Name', rules: { required: true, }, labelCss: 'font-normal text-custom-gray-500 mb-1', placeholder: 'Enter name', span: 'col-span-12', use: 'antd', className: "text-xs h-12 " },
        { type: 1, name: 'email_address', label: 'Email Address', rules: { required: true, validate: validations?.check_email }, labelCss: 'font-normal text-custom-gray-500 mb-1', placeholder: 'Enter Email Address', span: 'col-span-12', use: 'antd', className: "text-xs h-12 " },
        { type: 1, name: 'phone_number', label: 'Phone Number', rules: { required: true, validate: validations?.check_number }, labelCss: 'font-normal text-custom-gray-500 mb-1', placeholder: 'Enter Phone Number', span: 'col-span-12', use: 'antd', className: "text-xs h-12 " },
        { type: 1, name: 'description', label: 'Description', rules: { required: true, }, labelCss: 'font-normal text-custom-gray-500 mb-1', placeholder: 'Enter Description', span: 'col-span-12', use: 'antd', className: "text-xs h-12 " },
        { type: 1, name: 'lawyer_id', label: 'Lawyer-ID', rules: { required: true, validate: validations?.check_number }, labelCss: 'font-normal text-custom-gray-500 mb-1', placeholder: 'Enter Officer ID', span: 'col-span-12', use: 'antd', className: "text-xs h-12 " },
        { type: 1, name: 'state', label: 'State', rules: { required: true, }, labelCss: 'font-normal text-custom-gray-500 mb-1', placeholder: 'Enter State', span: 'col-span-12', use: 'antd', className: "text-xs h-12 " },
        { type: 4, name: `featured`, label: <span className='text-xs text-custom-gray-700'>{'Featured Lawyer'} </span>, rules: { required: false }, className: "w-full" },
    ]
    const handleSubmit = async (val) => {
        console.log(modalData)
        // const result = await
        if (!image) return Message?.error('Kindly Upload Image!')
        if (modalData) {
            const result = await updateImmigrationOfficer(modalData?.id, {
                ...val,
                profile_picture: image
            })
            if (result?.success) handleModal('add')
        } else {
            const result = await addImmigrationOfficer({
                ...val,
                profile_picture: image
            })
            if (result?.success) handleModal('add')
        }
        fetchOfficers()
        console.log(val)
    }
    const handleFile = async (file, type) => {
        const result = await uploadApi([file], 6)
        if (type == 'invoice') {
            setInvoiceImage(result)
        } else {
            setImage(result)
        }
    }
    const createInvoice = async () => {
        if (!invoiceImage) return Message?.error('Invoice Image is required!')
    }
    const handleOfficerFields = () => {
        ['name', 'email_address', 'phone_number', 'description', 'lawyer_id', 'state', 'featured'
        ].map((item) => {
            Form2?.setValue(item, modalData?.[item] ? modalData?.[item] : '')
        })
        console.log(modalData)
        setImage(modalData?.profile_picture)
    }
    useEffect(() => {
        if (modalData) handleOfficerFields()
        else {
            setImage('')
        }
    }, [modalData])
    useEffect(() => {
        fetchOfficers()
    }, [queryFilters?.search, queryFilters?.date, queryFilters?.userType, currentPage])
    return (
        <div className='bg-white p-3 rounded-md'>
            <div>
                <Header heading={'Immigration Officer Listing'} >
                    <Button onClick={() => { handleModal('add'); setModalData(false) }} buttonType='blue'><PlusOutlined />  Add Immigration Officer</Button>
                </Header>
                <Divider className='my-2' />
            </div>
            <FormController
                Form={Form}
                fields={fields}
                onSubmit={() => { }}
                className={'grid grid-cols-12 gap-2'}
            />
            <Loader keys={['getOfficers']}>
                <DynamicTable column={getColumn('lawyerListing', handleModal, setModalData)} pagination={false} data={data}
                    className={`border border-custom-gray-200  rounded-md mt-3`} />
            </Loader>
            {/* Add/Update Lawyer */}
            <Modal width={600} title={modalData ? 'Update Immigration Officer' : 'Add Immigration Officer'} open={modal?.add} onCancel={() => { handleModal('add') }} footer={null}>
                <Loader keys={['uploadApi', 'addImmigrationOfficer', 'updateImmigrationOfficer']}>
                    <div className='flex justify-between  items-center my-4'>
                        <div className='flex flex-col'>
                            <p className='font-bold text-[11px]'>{'Cover Photo'}</p>
                            <p className='text-custom-gray-500 text-[10px]'>{'This will be set as Lawyer cover photo.'}</p>
                        </div>
                        <div className='flex justify-between w-[50%]  items-center mb-[20px]'>
                            {image ? <img src={image || ImageImports?.noProfilePic} className={'rounded-full h-[80px] w-[80px] object-cover'} /> : <div></div>}
                            <div className='flex gap-3  w-'>
                                {
                                    image &&
                                    <Button onClick={() => setImage('')} additionalClassName='shadow-none text-custom-gray-500 border-none text-[11px] p-0 font-bold'>{'Delete'}</Button>
                                }
                                <UploadButton fileType={'image'} setImage={(file) => handleFile(file, 'image')} button={<Button buttonType='lightBlue'>{image ? 'Update' : 'Upload'}</Button>} />
                            </div>
                        </div>
                    </div>
                </Loader>
                <Loader keys={['addImmigrationOfficer', 'updateImmigrationOfficer']}>

                    <FormController
                        Form={Form2}
                        fields={modalFields}
                        onSubmit={handleSubmit}
                        className={'flex w-[100%] flex-col gap-5'}
                        submitButton={<Button htmlType='submit' additionalClassName='font-semibold w-full h-[45px] text-[14px] rounded-[10px] shadow-xl' buttonType='blue'>
                            {modalData ? "Update" : 'Submit'}
                        </Button>}
                    />
                </Loader>
            </Modal>
            {/* Delete Lawyer Profile */}
            <CustomModal
                width={450}
                stateReason={'Are you sure, you want to delete this Immigration Officer?'}
                modalTitle='Delete'
                open={modal?.delete}
                close={() => { handleModal('delete') }}
                field={<div className='w-full mt-5'>
                    <Button onClick={async () => { await deleteOfficer(modalData?.id); handleModal('delete'); fetchOfficers() }} additionalClassName='w-full' buttonType='darkRed'>
                        Delete
                    </Button></div>
                }
            />
            {/* Lawyer Payment Invoices */}
            <Modal width={600} title="Invoice Listing" open={modal?.invoice} onCancel={() => { handleModal('invoice') }} footer={null}>
                <div className='flex justify-end my-2'>
                    <Button onClick={() => { handleModal('addInvoice') }} className='' buttonType='blue'>
                        <PlusOutlined />    Add Invoice
                    </Button>
                </div>
                <Loader keys={['getAllUsers']}>
                    <DynamicTable column={getColumn('lawyerInvoiceListing', handleModal,)} pagination={false} data={data}
                        className={`border border-custom-gray-200  rounded-md mt-3`} />
                </Loader>
            </Modal>
            {/* Add Invoice*/}
            <Modal centered={true} width={600} title="Add Invoice" open={modal?.addInvoice} onCancel={() => { handleModal('addInvoice') }} footer={null}>
                <Loader keys={['uploadApi']}>
                    <div className='flex justify-between  items-center my-4'>
                        <div className='flex flex-col'>
                            <p className='font-bold text-[11px]'>Invoice Picture</p>
                            <p className='text-custom-gray-500 text-[10px]'>Kindly Upload Invoice Picture.</p>

                        </div>
                        <div className='flex justify-between w-[50%]  items-center mb-[20px]'>
                            {invoiceImage ? <img src={invoiceImage || ImageImports?.noProfilePic} className={'rounded-full h-[80px] w-[80px] object-cover'} /> : <div></div>}
                            <div className='flex gap-3  w-'>
                                {
                                    invoiceImage &&
                                    <Button onClick={() => setInvoiceImage('')} additionalClassName='shadow-none text-custom-gray-500 border-none text-[11px] p-0 font-bold'>{'Delete'}</Button>
                                }
                                <UploadButton fileType={'image'} setImage={(file) => handleFile(file, 'invoice')} button={<Button buttonType='lightBlue'>{image ? 'Update' : 'Upload'}</Button>} />
                            </div>
                        </div>
                    </div>
                    <Button onClick={createInvoice} buttonType='blue' additionalClassName='w-full'>
                        Save
                    </Button>
                </Loader>
            </Modal>
        </div>
    )
})

export default LawyerListing