import React from 'react'

const UnderMaintenance = () => {

    const pageStyles = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        minHeight: "100vh"
    }

    return (
        <div
            style={pageStyles}>
            <h1>App is Under Maintenance!</h1>
            <h3>Contact Support</h3>
        </div>
    )
}

export default UnderMaintenance