import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivateRoutes from "./Private";
import { DynamicRoutes } from "./index";
import { DashboardLayout, ReqProvider } from "../Components/Layouts";
import { ErrorBoundary, ErrorPage } from "../Components/ErrorHandlers";

const RouteHandler = () => {

    return (
        <Router>
            {/* Global Error Boundary */}
            <ErrorBoundary fallback={<ErrorPage />} >
                {/* Provider for Dispatch, Navigate Gobally */}
                <ReqProvider>
                    {/* Routes */}
                    <Routes>
                        {/* Auth Routes */}
                        {DynamicRoutes.auth.map(({ component, path }, index) => (
                            <Route
                                exact
                                key={index}
                                path={path}
                                element={component} />
                        ))}
                        {/* Protected Routes Custom Dashboard View */}
                        <Route element={<PrivateRoutes />}>
                            {DynamicRoutes.dashboard.map(({ component, path, key }, index) => (
                                <Route
                                    exact
                                    key={index}
                                    path={path}
                                    element={<DashboardLayout sidebarKey={key} children={component} />} />
                            ))}
                        </Route>
                    </Routes>
                </ReqProvider >
            </ErrorBoundary >
        </Router >
    )
};

export default RouteHandler;
