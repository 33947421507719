import { Navigate, Outlet } from "react-router-dom";
import { pageRoutes } from "./page.routes";
import { tokenId } from "../Environments/environment";
import { LocalStorage } from "../Utility/Services";
const Storage = new LocalStorage()

const PrivateRoutes = () => {

    /* Auth logic goes here */

    let token = Storage.get(`authToken-${tokenId}`)
    let verdict = token ? true : false
    return (
        !verdict ? <Outlet /> : <Navigate to={pageRoutes.login} />
    )
}

export default PrivateRoutes