import React, { useState } from 'react'
import { Image } from 'antd'
import { ImageImports } from '../../../Assets/Images'
import FormController from '../../FormControl/FormController'
import { useForm } from 'react-hook-form'
import Button from '../../Dynamics/Button'
import { login } from 'API/Services'
import Loader from 'Components/Loader/Loader'
import { useNavigate } from 'react-router-dom'
import { pageRoutes } from 'Routing/page.routes'

const Login = () => {
  const Form = useForm()
  const fields = [
    { type: 1, name: 'email_address', label: 'Email', rules: { required: true, }, labelCss: 'font-normal text-custom-gray-500 mb-1', placeholder: 'Enter your Email Address', span: 'col-span-12', use: 'antd', className: "text-xs h-12 " },
    { type: 7, name: 'password', label: 'Password', labelCss: 'font-normal text-custom-gray-500 mb-1 ', rules: { required: true }, placeholder: 'Enter your Password', span: 'col-span-12', use: 'antd', className: "text-xs h-12" },
  ]
  const handleSubmit = async (val) => {
    console.log('hello world')
    await login(val)
  }
  return (
    <div className='h-[100vh] flex '>
      <div className='w-[100%] h-full  bg-[#041f77] '>
        <div className=' flex flex-col items-center justify-center  h-full'>
          <Image src={ImageImports?.SignUpImage} preview={false} />
          <p className='font-bold text-[20px] my-2 text-white'>US Citizenship Admin Dashboard </p>
          <p className='text-[12px] text-[#F8FAFC] font-thin'></p>
        </div>
      </div>
      <div className='w-[100%] h-full flex flex-col items-center justify-center '>
        <div className='my-8 w-[60%] text-center'>
          <h1 className='text-[26px] font-semibold mb-2'>Sign In to your Account</h1>
          <p className='text-[11px] text-custom-gray-500 tracking-wide	'>Welcome back! please enter your detail</p>
        </div>
        <div className='w-[60%]'>
          <Loader keys={['login']} className='w-full'>
            <FormController
              Form={Form}
              fields={fields}
              onSubmit={handleSubmit}
              className={'flex w-[100%] flex-col gap-5'}
              submitButton={<Button htmlType='submit' additionalClassName='font-semibold w-full h-[45px] text-[14px] rounded-[10px] shadow-xl' buttonType='blue'>Submit</Button>}
            />
          </Loader>
        </div>
      </div>
    </div>
  )
}

export default Login