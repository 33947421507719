import { socket } from 'Libraries/socket';
import './App.css';
import { MaintenanceRouter } from './Components/ErrorHandlers';
import FormExample from './Components/FormControl/FormExample';
import { appConfig } from './Environments/config';
import RouteHandler from './Routing/RouteHandler';
import { useEffect } from 'react';

const App = () => {

  useEffect(() => {
    socket.onopen = () => {
    };
  }, [])

  return (
    appConfig.underMaintenance
      ?
      <MaintenanceRouter />
      :
      <RouteHandler />
  )
}

export default App;
