import React, { useEffect } from 'react'
import { Header } from '../../../Layout.Components'
import { Divider } from 'antd'
import DynamicTable from '../../../Layout.Components/Custom/DynamicTable'
import { getColumn } from '../../../../Utility/Columns'
import Loader from 'Components/Loader/Loader'
import { FormController } from 'Components/FormControl'
import { useForm } from 'react-hook-form'
import Button from 'Components/Dynamics/Button'
import { useParams } from 'react-router-dom'
import { createAnnouncement, getAnnouncementById } from 'API/Services/announcement.service'

const CreateAnnouncement = () => {
  const { id } = useParams()
  const Form = useForm()
  const fields = [
    { type: 1, divClassName: 'flex justify-between items-center', placeholder: "Enter title", divider: false, name: 'title', label: 'Title', rules: { required: true }, use: 'antd', span: "", onChange: (value) => { }, className: "w-[100%] text-[11px] h-[32px]" },
    { type: 11, divClassName: 'flex justify-between items-center', placeholder: "Enter description", labelClassName: 'w-full', divider: false, name: 'description', label: 'Description', rules: { required: true }, use: 'antd', span: "", onChange: (value) => { }, className: "w-[100%] text-[11px] h-[32px]" },
    { type: 1, divClassName: 'flex justify-between items-center', placeholder: "Add external link", divider: false, name: 'external_link', label: 'External Link', rules: { required: false }, use: 'antd', span: "", onChange: (value) => { }, className: "w-[100%] text-[11px] h-[32px]" },
  ]
  const handleSubmit = async (val) => {
    if (!id) await createAnnouncement(val)
    else { }
  }
  const fetchAnnouncementById = async () => {
    if (id) {
      const result = await getAnnouncementById(id)
      Form?.setValue('title', result?.title)
      Form?.setValue('description', result?.description)
    }
  }
  useEffect(() => {
    fetchAnnouncementById()
  })
  return (
    <Loader keys={['createAnnouncement', 'getAnnouncementById']}>
      <div className='bg-[white] p-4 rounded-md'>
        <Header heading={id ? 'Announcement Detail' : 'Create Announcement'} />
        <Divider className='my-2' />
        <div className=' grid grid-cols-12'>
          <div className='col-span-8'>
            <FormController
              formType={'antd'}
              Form={Form}
              onSubmit={handleSubmit}
              className="flex flex-col gap-10"
              submitButton={
                !id && <div className='flex justify-end'>
                  <Button buttonType='blue' htmlType='submit' additionalClassName='font-semibold text-[13px]'>
                    {id ? 'Update Announcement' : 'Create Announcement'}
                  </Button>
                </div>
              }
              fields={fields} />
          </div>
        </div>
      </div >
    </Loader>

  )
}

export default CreateAnnouncement