import { getMessaging, getToken } from "firebase/messaging";
import firebase from './firebase'
import { Message } from "Utility/Services";
export const firebaseTokenGenerator = async () => {
    try {
        let currentToken = await getToken(firebase?.messaging, { vapidKey: 'BH_YTgKME1n8UQPYzR-VieOY2Uqqgy3GGn1yh_DUsBOWXqt-N6muuSEZkJlSkPLdCg2WHklqRun5b6luNbBl1Yg' })
        currentToken = currentToken
        return currentToken
    } catch (e) {
        // console.log(e)
        // Message?.error(e?.message)
        return ''
    }
}

