import React from 'react'
import { Image as AntImage } from 'antd'
import { ImageImports } from "../../../Assets/Images"

const Image = ({ type = false, src = null, className, width, height, preview = false, alt = "", onClick }) => {
    const imageTypes = {
        logo: ImageImports?.logo,
        noPreview: ImageImports?.uploadUser
    }
    return (
        <AntImage
            alt={alt}
            onClick={onClick}
            className={`${className} ${type === "noPreview" && "bg-custom-primary-50"}`}
            width={width}
            height={height}
            src={type ? imageTypes[type] : src}
            preview={preview}
        />
    )
}

export default Image