import { ImageImports } from 'Assets/Images'
import Button from 'Components/Dynamics/Button'
import Image from 'Components/Dynamics/Image'
import { FormService } from 'Components/FormControl'
import { categoriesN400, CheckProp } from 'Utility'
import { validations } from 'Utility/Validations'
import { Divider } from 'antd'
import React, { useEffect, useState } from 'react'

const MCQS = ({ form, count, data }) => {
    const [formFields, setFormFields] = useState([])
    const [audioUrl, setAudioUrl] = useState(false)
    const questionField = [
        { type: 1, name: `Data[${count}].question`, disabled: true, defaultValue: data?.question, label: 'Question', rules: { required: false, validate: validations?.checkEmptySpace }, placeholder: 'Write question', use: 'antd', span: "col-span-12", },
        { type: 8, name: `Data[${count}].category`, defaultValue: data?.category, label: 'Category', options: categoriesN400, rules: { required: true }, disabled: true, placeholder: 'Category', use: 'antd', span: "col-span-12", },
        { type: 1, defaultValue: data?.order, name: `Data[${count}].order`, label: 'Order No.', rules: { required: false }, disabled: true, placeholder: 'Order number', use: 'antd', span: "col-span-12", },
        { type: 102, label: 'Upload Question Audio', disabled: true, audioUrl: audioUrl, setaudioUrl: setAudioUrl, name: `audio`, span: "col-span-12", },
        { type: 1, divClassName: ' invisible   justify-between  h-[0px] overflow-hidden', name: `Data[${count}].type`, disabled: true, defaultValue: 1, label: 'FEE', rules: { required: false }, placeholder: 'PH_FEES', use: 'antd', span: "", onChange: (value) => { }, className: " text-[11px] w-[200px] " },
    ]
    const initFields = (innerCount = 0, value = '') => {
        return [{ type: 1, divClassName: 'my-2', disabled: true, defaultValue: value, name: `Data[${count}].symptom_choices[${innerCount}].description`, label: '', rules: { required: false, validate: validations?.checkEmptySpace }, placeholder: '', use: 'antd', span: "", },]
    }
    const hanldeFieldsValue = () => {
        if (data?.Choices?.length !== 0) {
            let newFields = [...formFields]
            data?.Choices?.forEach((item, index) => {
                const { choice } = item
                newFields.push(initFields(newFields?.length, choice))
            })
            setFormFields(newFields)
        }
    }
    useEffect(() => {
        hanldeFieldsValue()
        setAudioUrl(data?.audio_url)
    }, [data])
    return (
        <div className='border border-custom-gray-300 rounded-md p-3 w-full bg-white'>
            <FormService
                Form={form}
                fields={questionField}
                className={'grid grid-cols-12 gap-2'}
            />
            <CheckProp prop={formFields?.length !== 0}>
                <p className='text-[11px] font-semibold text-[#202939] my-2'>{'CHOICES'}</p>
            </CheckProp>
            {
                formFields?.map((item, index) => (
                    item.length !== 0 && <div className='w-full gap-2 flex'>
                        <FormService Form={form}
                            fields={item}
                            className={'grid grid-cols-12 gap-2 w-full my-2'} />
                        {/* <Button additionalClassName='border-none shadow-none mt-[-5px]' onClick={() => { handleField('delete', index) }}><Image src={ImageImports?.closeGray} /></Button> */}
                    </div>
                ))
            }
            <Divider className='my-2' />
            {/* <Button onClick={() => handleField('add')} additionalClassName='w-full font-semibold text-[11px] shadow-none'><Image src={ImageImports?.addAnother} />{'Add Option'}</Button> */}
        </div>
    )
}

export default MCQS