import { Rate } from "antd"
import { CheckProp } from "../../../Utility"
import Image from "../Image"

export const Tag = ({ width = "", ...props }) => {
    return <div className={`flex flex-col justify-center gap-1 ${props?.mainDivClassName} `}>
        <CheckProp prop={props?.text}><p className={`text-[9px] ${props?.textCSS}`} >{props?.text}</p></CheckProp>
        <div className={`${props?.className} ${props?.className && 'p-2'}  rounded-xl  flex gap-2 items-center h-[25px] box-border`}>
            <CheckProp prop={props?.image}><Image src={props?.image} height={props?.imgHeight || 22} /></CheckProp>
            <CheckProp prop={props?.label}><p className="text-[10px] font-[600] min-w-max"> {props?.label}  </p> </CheckProp>
            <CheckProp prop={props?.rating}><Rate className="text-custom-warning-400 text-[11px]" allowHalf disabled defaultValue={props?.rating} /> </CheckProp>
        </div>
    </div>
}
export const DashboardTag = ({ width = "", ...props }) => {
    return <div className={`flex flex-col justify-center gap-1 ${props?.mainDivClassName} `}>
        <CheckProp prop={props?.text}><p className={`text-[9px] ${props?.textCSS}`} >{props?.text}</p></CheckProp>
        <div className={`${props?.className} ${props?.className && 'p-2'}  rounded-xl  flex gap-2 items-center h-[25px] box-border`}>
            <CheckProp prop={props?.image}><Image src={props?.image} height={props?.imgHeight || 22} /></CheckProp>
            <CheckProp prop={props?.label}><p className="text-[14px] font-[600] min-w-max"> {props?.label}  </p> </CheckProp>
            <CheckProp prop={props?.rating}><Rate className="text-custom-warning-400 text-[11px]" allowHalf disabled defaultValue={props?.rating} /> </CheckProp>
        </div>
    </div>
}

export const DefaultTags = ({ type, ...props }) => {
    let map = {
        whiteBlue: <DashboardTag className="bg-[white] text-custom-primary-500" {...props} />,
        red: <Tag className="bg-custom-warning-50 text-custom-warning-700" {...props} />,
        green: <Tag className="bg-custom-success-50 text-custom-success-600" {...props} />,
        purple: <Tag className="bg-custom-purple-50 text-custom-purple-700" {...props} />,
        blue: <Tag className="bg-custom-blue-50 text-custom-blue-700" {...props} />,
        gray: <Tag className="bg-custom-gray-100 text-custom-gray-700" {...props} />,

    }

    return map[type]
}