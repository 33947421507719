import React, { useEffect, useState } from 'react'
import { Header } from '../../../Layout.Components'
import { Divider } from 'antd'
import DynamicTable from '../../../Layout.Components/Custom/DynamicTable'
import { getColumn } from '../../../../Utility/Columns'
import pagination from 'Components/HighOrderFunctions/Pagination'
import { useForm } from 'react-hook-form'
import { FormController } from 'Components/FormControl'
import Image from 'Components/Dynamics/Image'
import { ImageImports } from 'Assets/Images'
import { SearchOutlined } from '@ant-design/icons'
import { getAllAnnouncement } from 'API/Services/announcement.service'
import Loader from 'Components/Loader/Loader'

const AllAnnouncement = pagination((props) => {
  const { currentPage, setTotal, setCurrentPage } = props
  const Form = useForm()
  const [data, setData] = useState([])
  const [queryFilters, setQueryFilters] = useState({
    title: '',
  })
  const fields = [
    { type: 10, name: 'title', state: setQueryFilters, stateKey: "title", userOnChange: () => { setCurrentPage(1) }, rules: { required: false }, label: 'Search', placeholder: 'Search by announcement', use: 'antd', span: "col-span-3", className: "h-[40px]", icon: <SearchOutlined /> },
  ]
  const fetchAnnouncement = async () => {
    const result = await getAllAnnouncement(queryFilters)
    setData(result)
  }
  useEffect(() => {
    fetchAnnouncement()
  }, [queryFilters?.title])
  return (
    <div className='bg-white p-3 rounded-md'>
      <div>
        <Header heading={'Announcements'} />
        <Divider className='my-2' />
        <FormController
          Form={Form}
          fields={fields}
          onSubmit={() => { }}
          className={'grid grid-cols-12'}
        />
      </div>
      <Loader keys={['getAllAnnouncement']}>
        <DynamicTable column={getColumn('announcementListing')} pagination={false} data={data}
          className={`border border-custom-gray-200  rounded-md mt-3`} />
      </Loader>
    </div>
  )
})

export default AllAnnouncement