import React, { useEffect } from 'react'
import { pageRoutes } from 'Routing/page.routes'
import { utility } from 'Utility'

const NotFound = () => {
    useEffect(()=>{
   utility?.navigate(pageRoutes?.login)
    },[])
    return (
        <div className='h-[100vh] flex text-center justify-center items-center'>
            Error 404 Page Not Found!
        </div>
    )
}

export default NotFound