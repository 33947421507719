
import { catchAsyncErrors } from 'API/Handlers';
import { deleteRequest, getRequest, postRequest, putRequest } from 'API/api.methods';
import { apiRoutes } from 'API/api.routes';
import { setPageLoader, verification } from 'Redux/Features';
import { pageRoutes } from 'Routing/page.routes';
import { addQueryParams, utility } from 'Utility';
import { Message, Storage } from 'Utility/Services';
import { get } from 'react-hook-form';

const getQuizById = catchAsyncErrors(async (id) => {
    utility.dispatch(setPageLoader({ page: 'getQuizById', flag: true }))
    let result = await getRequest({
        endPoint: addQueryParams(apiRoutes?.quiz?.getQuizById + id),

    })
    utility.dispatch(setPageLoader({ page: 'getQuizById', flag: false }))
    return result?.Quiz
})
const createQuiz = catchAsyncErrors(async (body) => {
    utility.dispatch(setPageLoader({ page: 'createQuiz', flag: true }))
    let result = await postRequest({
        endPoint: addQueryParams(apiRoutes?.quiz?.createQuiz),
        body: body
    })
    if (result?.success) Message?.success('Question Added on Quiz')
    utility.dispatch(setPageLoader({ page: 'createQuiz', flag: false }))
    return result
})
const updateQuizQuestion = catchAsyncErrors(async (id, body) => {
    utility.dispatch(setPageLoader({ page: 'updateQuizQuestion', flag: true }))
    let result = await putRequest({
        endPoint: addQueryParams(apiRoutes?.quiz?.updateQuiz + id),
        body: body
    })
    if (result?.success) Message?.success('Question Updated Successfully!')
    utility.dispatch(setPageLoader({ page: 'updateQuizQuestion', flag: false }))
    return result
})
const addQuestion = catchAsyncErrors(async (id, body) => {
    utility.dispatch(setPageLoader({ page: 'addQuestion', flag: true }))
    let result = await postRequest({
        endPoint: addQueryParams(apiRoutes?.quiz?.addQuestion + id),
        body: body
    })
    if (result?.success) Message?.success('Question Added on Quiz')
    utility.dispatch(setPageLoader({ page: 'addQuestion', flag: false }))
    return result
})
const deleteQuiz = catchAsyncErrors(async (id) => {
    utility.dispatch(setPageLoader({ page: 'deleteQuiz', flag: true }))
    let result = await deleteRequest({
        endPoint: addQueryParams(apiRoutes?.quiz?.deleteQuiz + id),
    })
    Message?.success(result?.Message)
    utility.dispatch(setPageLoader({ page: 'deleteQuiz', flag: false }))
    return result
})
const deleteQuestion = catchAsyncErrors(async (id) => {
    utility.dispatch(setPageLoader({ page: 'deleteQuestion', flag: true }))
    let result = await deleteRequest({
        endPoint: addQueryParams(apiRoutes?.quiz?.deleteQuestion + id),
    })
    Message?.success(result?.Message)
    utility.dispatch(setPageLoader({ page: 'deleteQuestion', flag: false }))
    return result
})
//get By Id

export { updateQuizQuestion, getQuizById, createQuiz, deleteQuiz, deleteQuestion, addQuestion }