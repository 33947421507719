import React, { useEffect } from 'react'
import Button from '../Button'
import { Pagination } from 'antd'
import Image from '../Image'
import { ImageImports } from '../../../Assets/Images'


const CustomPagination = ({ onChange, total, current, withTable, setCurrentPage, pageSize, prev, next, pageSizeOptions = [], showSizeChanger }) => {


  const itemRender = (page, type, originalElement) => {

    if (type === "prev") {
      return prev || <Button
        additionalClassName={'text-[10px] text-custom-gray-900 font-bold shadow-md w-[100px] '}
        btnIcon={<Image src={ImageImports?.LongArrowLeft} width={14} />}
        gap="gap-2"
        onClick={() => window.scrollTo(0, 0)}
      >
        {"PREVIOUS"}
      </Button>
    }
    if (type === "next") {
      return next || <Button
        additionalClassName={'text-[10px] text-custom-gray-900 font-bold shadow-md w-[100px]'}
        btnIconEnd={<Image src={ImageImports?.LongArrowRight} width={14} />}
        gap="gap-2"
        onClick={() => window.scrollTo(0, 0)}

      >
        {"NEXT"}
      </Button>;
    }

    return originalElement
  };

  return <div className={`bg-white p-3 w-full ${withTable && "border border-custom-gray-200 rounded-b-lg shadow-lg mt-[-2px]"}`}>
    <Pagination
      current={current}
      onChange={(page) => setCurrentPage(page)}
      total={total}
      defaultCurrent={1}
      pageSizeOptions={pageSizeOptions || []}
      className='text-[10px] w-full flex items-center justify-center relative'
      pageSize={pageSize || 10}
      showSizeChanger={showSizeChanger || false}
      itemRender={itemRender}
    />
  </div>

}

export default CustomPagination