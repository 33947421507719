import { uploadApi } from 'API/Services/generic.service'
import { uploadRequest } from 'API/api.methods'
import { FormService } from 'Components/FormControl'
import { categoriesN400 } from 'Utility'
import { Divider } from 'antd'
import React, { useEffect, useState } from 'react'

const QuestionTest = ({ form, count, data }) => {
    const [audioUrl, setAudioUrl] = useState(false)

    const questionField = [
        { type: 1, defaultValue: data?.question, name: `Data[${count}].title`, label: 'Question', rules: { required: false }, disabled: true, placeholder: 'Write question', use: 'antd', span: "col-span-12", },
        { type: 8, name: `Data[${count}].category`,defaultValue: data?.category, label: 'Category', options: categoriesN400, rules: { required: true },disabled:true,placeholder: 'Category', use: 'antd', span: "col-span-12", },
        { type: 1, defaultValue: data?.order, name: `Data[${count}].order`, label: 'Order No.', rules: { required: false }, disabled: true, placeholder: 'Order number', use: 'antd', span: "col-span-12", },
        { type: 102, label: 'Upload Question Audio', disabled: true, name: `audio`,audioUrl: audioUrl, setaudioUrl: setAudioUrl, span: "col-span-12", audio: data?.audio_url || false, },

        // its default field and it will go as it is 
        { type: 1, divClassName: ' invisible   justify-between  h-[0px] overflow-hidden', name: `Data[${count}].type`, defaultValue: 2, label: '', rules: { required: false }, placeholder: '', use: 'antd', span: "", onChange: (value) => { }, className: " text-[11px] w-[200px] " },
    ]
    useEffect(() => {
        setAudioUrl(data?.audio_url)
    }, [data])
    return (
        <div className='border border-custom-gray-300 rounded-md p-3 w-full bg-[white]'>
            <FormService
                Form={form}
                fields={questionField}
                className={'grid grid-cols-12 gap-2'}
            />
            <Divider />
        </div>
    )
}

export default QuestionTest