import SignUpImage from './SignUpImage.svg'
// import Logo from './Logo.svg'
import Avatar from './Avatar.svg'
import noProfilePic from './noProfilePic.jpg'
import deleteModalIcon from './deleteModalIcon.svg'
import arrowDownGray500 from './arrowDownGray500.svg'
import closeGray from './closeGray.svg'
import Logo from './Logo.png'

export const ImageImports = {
    closeGray,
    arrowDownGray500,
    deleteModalIcon,
    SignUpImage,
    Logo,
    Avatar,
    noProfilePic
}


