import React from 'react'

const Instructions = () => {
    return (
        <div className='' >
            <div className='p-3 bg-custom-primary-500'>
                <p className='text-white text-[30px]'>Steps to Delete Your Account</p>
            </div>
            <div className='p-3'>
                <p className='text-[20px] my-4 font-bold'>Login to Your Account
                </p>
                <p>
                    1. Open the app or website and navigate to the login page. <br />
                    2. Enter your username or email address in the provided field.<br />
                    3. Type in your password.<br />
                    4. Click on the "Login" button to access your account.<br />
                </p>
                <p className='text-[20px] my-4 font-bold'>Navigate to Profile Settings
                </p>
                <p>
                    1. Within the sidebar menu, scroll through the options until you find "Profile Settings." <br />
                    2. Tap on "Profile Settings" to access the profile management section.<br />
                </p>
                <p className='text-[20px] my-4 font-bold'>Select Profile Settings:
                </p>
                <p>
                    1. Tap on "Profile Settings" from the menu options.
                </p>
                <p className='text-[20px] my-4 font-bold'>Click on Delete Profile:
                </p>
                <p>
                    1. In the "Profile Settings" section, look for the "Delete Profile" option. <br />
                    2. Click on "Delete Profile" to initiate the deletion process.
                </p>
                <p className='text-[20px] my-4 font-bold'>Confirm Your Decision:
                </p>
                <p>
                    1. A confirmation prompt will appear to ensure you want to proceed with deleting your account.<br />
                    2. Carefully read the message as this action is irreversible.<br />
                    3. If you are certain about deleting your profile, confirm your decision by clicking the appropriate button.
                </p>
            </div>
        </div>
    )
}

export default Instructions