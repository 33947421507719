import { Empty } from 'antd'
import React from 'react'

const CheckEmpty = ({ arr, type = "defaultEmpty", ...props }) => {

    const emptyMap = {
        defaultEmpty,
    }

    return arr ? props?.children : emptyMap[type]
}

export default CheckEmpty
const defaultEmpty = <div className='flex items-center justify-center h-[30vh]'>
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span className='text-[10px]'>{'No Data Found'}</span>} />
</div>

