const {
    REACT_APP_SOCKET_URL: socketsURL,
    REACT_APP_BASE_URL: baseURL,
    REACT_APP_TokenId: tokenId,
    REACT_APP_PORT: PORT,
    REACT_UNDER_MAINTENANCE: IS_UNDER_MAINTENANCE,
} = process.env;
export {
    socketsURL,
    PORT,
    tokenId,
    baseURL,
    IS_UNDER_MAINTENANCE
}