import { uploadApi } from 'API/Services/generic.service'
import { createN400Question, updateN400Question } from 'API/Services/n400.service'
import { addQuestion, createQuiz, updateQuizQuestion } from 'API/Services/quiz.service'
import { uploadRequest } from 'API/api.methods'
import { ImageImports } from 'Assets/Images'
import Button from 'Components/Dynamics/Button'
import Image from 'Components/Dynamics/Image'
import { FormController, FormService } from 'Components/FormControl'
import { validations } from 'Utility/Validations'
import { Divider } from 'antd'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

const McqQuiz = ({ modal, data = false, close, createQuizCheck, getQuiz, quizId, modalData }) => {
    console.log("🚀 ~ McqQuiz ~ modalData:", modalData)
    const { id } = useParams()
    const Form = useForm()
    const [formFields, setFormFields] = useState([])
    const questionField = [
        { type: 1, name: `question`, label: 'Question', rules: { required: true, validate: validations?.checkEmptySpace }, placeholder: 'Write question', use: 'antd', span: "col-span-12", },
    ]
    const initFields = (innerCount = 0, choice = '', isCorrect = false) => {
        return [
            { type: 1, divClassName: 'my-2', defaultValue: choice, name: `choices[${innerCount}].choice`, label: '', rules: { required: true, validate: validations?.checkEmptySpace }, placeholder: '', use: 'antd', span: "", },
            { type: 4, divClassName: 'my-2', defaultValue: isCorrect, name: `choices[${innerCount}].is_correct_choice`, label: 'Correct Answer', rules: { required: false, }, placeholder: '', use: 'antd', span: "", },
        ]
    }
    const handleField = (type, index, value = '') => {
        let newFields = [...formFields]
        if (type === 'add') {
            newFields.push(initFields(newFields?.length, value))
            setFormFields(newFields)
        } else {
            newFields.splice(index, 1, [])
            setFormFields(newFields)
            unRegisterField(index)
        }
    }
    const unRegisterField = (index) => {
        Form.unregister(`choices[${index}].choice`)
        Form.unregister(`choices[${index}].is_correct_choice`)
    }
    const hanldeFieldsValue = () => {
        if (data?.Choices?.length !== 0) {
            let newFields = []
            data?.Choices?.forEach((item, index) => {
                const { choice, is_correct_choice } = item
                Form?.setValue(`choices[${newFields?.length}].choice`, choice)
                Form?.setValue(`choices[${newFields?.length}].is_correct_choice`, is_correct_choice)
                newFields.push(initFields(newFields?.length, choice, is_correct_choice))
            })
            setFormFields(newFields)
        }
    }
    const handleSubmit = async (val) => {
        let body = {
            question: val?.question,
            choices: val?.choices ? val?.choices?.filter(item => {
                if (item) {
                    return {
                        ...item,
                        is_correct_choice: item?.is_correct_choice || false
                    }
                }
            }) : [],
        }
        if (modalData) { await updateQuizQuestion(modalData?.id, body) }
        else if (createQuizCheck) {
            let newData = {
                questions: [body],
                name: "Quiz",
                course: id
            }
            const result = await createQuiz(newData)
        }
        else await addQuestion(quizId, body)
        await getQuiz()
        Form?.setValue('choices', '[]');
        Form?.setValue('question', '');
        Form?.reset()
        setFormFields([])
        close()

    }
    useEffect(() => {
        if (data) {
            Form?.reset()
            setFormFields([])
            Form?.setValue('choices', '[]');
            Form?.setValue('question', data?.question)
            hanldeFieldsValue()
        } else {
            Form?.reset()
            Form?.setValue('choices', '[]');
            Form?.setValue('question');
            setFormFields([])
        }
    }, [data])
    return (
        <div className='border border-custom-gray-300 rounded-md p-3 w-full bg-[white] mt-3'>
            <form onSubmit={Form.handleSubmit(handleSubmit)}>
                <FormService
                    Form={Form}
                    fields={questionField}
                    className={'grid grid-cols-12 gap-2 '}
                />
                {
                    formFields?.map((item, index) => (
                        item.length !== 0 && <div className='w-full gap-2 flex items-center'>
                            <FormService Form={Form}
                                fields={item}
                                className={'grid grid-cols-12 gap-2 w-full my-4'} />
                            <Button additionalClassName='border-none shadow-none ' onClick={() => { handleField('delete', index) }}><Image src={ImageImports?.closeGray} /></Button>
                        </div>
                    ))
                }
                <div className='flex gap-3 my-4'>
                    <Button onClick={() => handleField('add')} additionalClassName='w-full font-semibold text-[11px] shadow-none'><Image src={ImageImports?.addAnother} />{'Add Option'}</Button>
                    <Button additionalClassName='w-full col-span-12' htmlType='submit' buttonType='blue'>
                        {data ? 'Update Question' : 'Create Question'}
                    </Button>
                </div>
                <Divider />
            </form>

        </div>
    )
}

export default McqQuiz