import React from 'react';
import { Button, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { checkAudioFiles, checkUploadedFileDoc, checkUploadedFileLicenseUrl, checkUploadedImage, debounce } from '../../Utility'
import { Message } from 'Utility/Services';


const UploadButton = ({ button, setImage = () => { }, fileType }) => {

    const onChange = debounce(async (data) => {
        const { file } = data
        var fileCheck
        if (fileType === 'file') { fileCheck = checkUploadedFileDoc(file?.originFileObj) }
        else if (fileType === 'licenseUrl') { fileCheck = checkUploadedFileLicenseUrl(file?.originFileObj) }
        else if (fileType === 'audio') { fileCheck = checkAudioFiles(file?.originFileObj) }
        else { fileCheck = checkUploadedImage(file?.originFileObj) }
        if (fileCheck) {
            Message.success('File is uploading!')
            setImage(file)
        }
        return
    })
    return <Upload showUploadList={false} action={false} onChange={onChange}>
        {button ? button : <Button className='flex gap-1 items-center text-[11px] font-semibold'> <UploadOutlined />Click to Upload</Button>
        }
    </Upload>
}

export default UploadButton;