import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    pageLoader: {},
}
export const utils = createSlice({
    name: "utils",
    initialState,
    reducers: {
        setPageLoader: (state, { payload }) => {
            let { page, flag } = payload
            state.pageLoader[page] = flag
        },
        clearLoaders: (state) => {
            state.pageLoader = {}
        }
    }
})
export const { setPageLoader, clearLoaders } = utils?.actions
export default utils.reducer