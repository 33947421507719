
import { catchAsyncErrors } from 'API/Handlers';
import { deleteRequest, getRequest, postRequest, putRequest } from 'API/api.methods';
import { apiRoutes } from 'API/api.routes';
import { setPageLoader, verification } from 'Redux/Features';
import { pageRoutes } from 'Routing/page.routes';
import { addQueryParams, utility } from 'Utility';
import { Message, Storage } from 'Utility/Services';

const getAllCourses = catchAsyncErrors(async (body) => {
    utility.dispatch(setPageLoader({ page: 'getAllCourses', flag: true }))
    let result = await putRequest({
        endPoint: apiRoutes?.course?.getAllCourse,
        body: body
    })
    utility.dispatch(setPageLoader({ page: 'getAllCourses', flag: false }))
    return result?.Courses
})
//get By Id
const getCourseById = catchAsyncErrors(async (id, query) => {
    utility.dispatch(setPageLoader({ page: 'getCourseById', flag: true }))
    let result = await getRequest({
        endPoint: addQueryParams(`${apiRoutes?.course?.getCourseById}${id}`, query),
    })
    utility.dispatch(setPageLoader({ page: 'getCourseById', flag: false }))
    return result
})
const getCourseItem = catchAsyncErrors(async (id, query, body) => {
    utility.dispatch(setPageLoader({ page: 'getCourseItem', flag: true }))
    let result = await getRequest({
        endPoint: addQueryParams(`${apiRoutes?.course?.getCourseItem}${id}`, query),
        body: body
    })
    utility.dispatch(setPageLoader({ page: 'getCourseItem', flag: false }))
    return result
})
// Create
const createCourse = catchAsyncErrors(async (body) => {
    utility.dispatch(setPageLoader({ page: 'createCourse', flag: true }))
    let result = await postRequest({
        endPoint: addQueryParams(`${apiRoutes?.course?.createCourse}`),
        body: body
    })
    Message?.success(result?.Message)
    utility.dispatch(setPageLoader({ page: 'createCourse', flag: false }))
    return result
})
const createCourseItem = catchAsyncErrors(async (body) => {
    utility.dispatch(setPageLoader({ page: 'createCourseItem', flag: true }))
    let result = await postRequest({
        endPoint: addQueryParams(`${apiRoutes?.course?.createCourseItem}`),
        body: body
    })
    Message?.success(result?.Message)
    utility.dispatch(setPageLoader({ page: 'createCourseItem', flag: false }))
    return result
})
//Delete
const deleteCourse = catchAsyncErrors(async (id) => {
    utility.dispatch(setPageLoader({ page: 'deleteCourse', flag: true }))
    let result = await deleteRequest({
        endPoint: addQueryParams(`${apiRoutes?.course?.deleteCourse}${id}`),
    })
    utility.dispatch(setPageLoader({ page: 'deleteCourse', flag: false }))
    Message?.success(result?.Message)
    return result
})
const deleteCourseItem = catchAsyncErrors(async (id) => {
    utility.dispatch(setPageLoader({ page: 'deleteCourseItem', flag: true }))
    let result = await deleteRequest({
        endPoint: addQueryParams(`${apiRoutes?.course?.deleteCourseItem}${id}`),
    })
    utility.dispatch(setPageLoader({ page: 'deleteCourseItem', flag: false }))
    return result
})
//Update
const updateCourse = catchAsyncErrors(async (id, body) => {
    utility.dispatch(setPageLoader({ page: 'updateCourse', flag: true }))
    let result = await putRequest({
        endPoint: addQueryParams(`${apiRoutes?.course?.updateCourse}${id}`),
        body: body
    })
    Message?.success(result?.Message)
    utility.dispatch(setPageLoader({ page: 'updateCourse', flag: false }))
    return result
})
const updateCourseItem = catchAsyncErrors(async (id, body) => {
    utility.dispatch(setPageLoader({ page: 'updateCourseItem', flag: true }))
    let result = await putRequest({
        endPoint: addQueryParams(`${apiRoutes?.course?.updateCourseItem}${id}`),
        body: body
    })
    Message?.success(result?.Message)
    utility.dispatch(setPageLoader({ page: 'updateCourseItem', flag: false }))
    return result
})
const deleteCourseAttachment = catchAsyncErrors(async (id,) => {
    utility.dispatch(setPageLoader({ page: 'deleteCourseAttachment', flag: true }))
    let result = await deleteRequest({
        endPoint: addQueryParams(`${apiRoutes?.course?.deleteCourseAttachment}${id}`),
    })
    Message?.success(result?.Message)
    utility.dispatch(setPageLoader({ page: 'deleteCourseAttachment', flag: false }))
    return result
})
const updateCourseAttachment = catchAsyncErrors(async (id, body) => {
    utility.dispatch(setPageLoader({ page: 'updateCourseAttachment', flag: true }))
    let result = await putRequest({
        endPoint: addQueryParams(`${apiRoutes?.course?.updateCourseAttachment}${id}`),
        body: body
    })
    Message?.success(result?.Message)
    utility.dispatch(setPageLoader({ page: 'updateCourseAttachment', flag: false }))
    return result
})
const createCourseAttachment = catchAsyncErrors(async (id, body) => {
    utility.dispatch(setPageLoader({ page: 'createCourseAttachment', flag: true }))
    let result = await putRequest({
        endPoint: addQueryParams(`${apiRoutes?.course?.createCourseAttachment}${id}`),
        body: body
    })
    Message?.success(result?.Message)
    utility.dispatch(setPageLoader({ page: 'createCourseAttachment', flag: false }))
    return result
})
export { createCourseAttachment, updateCourseAttachment, deleteCourseAttachment, getAllCourses, createCourse, createCourseItem, updateCourse, updateCourseItem, deleteCourse, deleteCourseItem, getCourseById, getCourseItem }