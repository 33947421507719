import moment from "moment";
import { Message, Storage } from "./Services";
import dayjs from "dayjs";

export function checkUploadedImage(file) {
    const allowedFormats = ['png', 'jpg', 'jpeg', 'JPEG', 'PNG', 'Png', 'JPG', 'Jpg'];
    const maxSizeMB = 4;

    // Check file format
    const fileNameParts = file.name.split('.');
    const fileExtension = fileNameParts[1].toLowerCase();
    if (!allowedFormats.includes(fileExtension)) {
        Message.error('Only PNG and JPG images are acceptable ')
        return false;
    }

    // Check file size
    const fileSizeMB = file.size / (1024 * 1024); // Convert bytes to megabytes
    if (fileSizeMB > maxSizeMB) {
        Message.error('File should be  should be less then 4 mb')
        return false
    }
    return true
}
export function checkUploadedFileLicenseUrl(file) {
    const allowedFormats = ['pdf', 'PDF', 'docx', 'wordx', 'doc', 'png', 'jpg', 'jpeg', 'JPEG', 'PNG', 'Png', 'JPG', 'Jpg'];
    const maxSizeMB = 5;
    // Check file format
    const fileNameParts = file.name.split('.');
    const fileExtension = fileNameParts[1].toLowerCase();
    if (!allowedFormats.includes(fileExtension)) {
        Message.error('Only PDF/Doc/Png/Jpg are acceptable only')
        return false;
    }

    // Check file size
    const fileSizeMB = file.size / (1024 * 1024); // Convert bytes to megabytes
    if (fileSizeMB > maxSizeMB) {
        Message.error('File should be  should be less then 5 mb')
        return false
    }
    return true
}
export function checkAudioFiles(file) {
    const allowedFormats = [
        'mp3', 'MP3',
        'wav', 'WAV',
        'aac', 'AAC',
        'flac', 'FLAC',
        'ogg', 'OGG',
        'wma', 'WMA',
        'm4a', 'M4A'
    ];
    const maxSizeMB = 5;
    // Check file format
    const fileNameParts = file.name.split('.');
    const fileExtension = fileNameParts[1].toLowerCase();
    if (!allowedFormats.includes(fileExtension)) {
        Message.error('Only Audio files are acceptable!')
        return false;
    }

    // Check file size
    const fileSizeMB = file.size / (1024 * 1024); // Convert bytes to megabytes
    if (fileSizeMB > maxSizeMB) {
        Message.error('File should be  should be less then 5 mb')
        return false
    }
    return true
}
export function formatMoney(amount = 0) {
    if (amount) {
        let number = Number(amount)
        const formattedAmount = "$ " + number?.toFixed(2)?.replace(/\d(?=(\d{3})+\.)/g, '$&,');
        return formattedAmount;
    }
    return 'N-A'
}
export function checkUploadedFileDoc(file) {
    const allowedFormats = ['pdf', 'PDF', 'docx', 'wordx', 'doc'];
    const maxSizeMB = 5;

    // Check file format
    const fileNameParts = file.name.split('.');
    const fileExtension = fileNameParts[1].toLowerCase();
    if (!allowedFormats.includes(fileExtension)) {
        Message.error('Only Pdf or Doc are acceptable ')
        return false;
    }

    // Check file size
    const fileSizeMB = file.size / (1024 * 1024); // Convert bytes to megabytes
    if (fileSizeMB > maxSizeMB) {
        Message.error('File should be  should be less then 5 mb')
        return false
    }
    return true
}

export function checkRemainingTime(dateTimeString) {
    const givenDate = new Date(dateTimeString);
    const currentDate = new Date();

    if (currentDate >= givenDate) {
        return true;
    } else {
        return false;
    }
}

export function getTimestampFromTime(timeString) {
    console.log("🚀 ~ file: helpers.js:21 ~ getTimestampFromTime ~ timeString:", timeString)
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    const currentDate = new Date();
    currentDate.setHours(hours);
    currentDate.setMinutes(minutes);
    currentDate.setSeconds(seconds);

    return currentDate;

}


export const appointmentType = {
    LAB: 1,
    DOCTOR: 2,
    THERAPY: 3,
    VACCINATION: 4
}

export const reportTypes = {
    USER: 1,
    BLOG: 2,
    COMMENT: 3,
    QUESTION: 4,
    ANSWER: 5
}

// Null Checks 

export const CheckLength = ({ arr, children, children2 }) => {
    return arr?.length ? children : children2
}

export const ifNoData = (data, text) => {
    return data ? data : <span className="text-[11px] text-custom-gray-600">{text}</span>
}

export const CheckProp = ({ prop, children }) => {
    return prop ? children : <></>
}

export const CheckPropWithChildren = ({ prop, children, showElse }) => {
    return prop ? children : showElse
}

export const checkNull = (txt) => {
    return txt ? txt : "N/A"
}

export const NullCheck = (value) => {
    return !value ? "N/A" : value
}

export const checkNullWithChildren = (value, children) => {
    return !value ? "N/A" : children
}

// ===========



export const debounce = (cb, delay = 1000) => {
    let timeout;
    return (...args) => {
        clearTimeout(timeout)
        timeout = setTimeout(() => {
            cb(...args)
        }, delay);
    }
}

export const debounceImage = (cb, delay = 1000) => {
    let timeout;
    return (...args) => {
        clearTimeout(timeout)
        timeout = setTimeout(() => {
            cb(...args)
        }, delay);
    }
}

export const checkUploadedFile = (file) => {
    let validTypes = ['image/png', 'image/jpeg', 'image/JPEG', 'image/PNG', 'image/SVG', 'image/JPG', 'image/jpg', 'image/svg']
    if (validTypes?.includes(file?.type)) {
        return true
    }
    return false
}

export const checkUploadedMultipleFiles = (files) => {
    let validTypes = ['image/png', 'image/jpeg', 'image/JPEG', 'image/PNG', 'image/SVG', 'image/JPG', 'image/jpg', 'image/svg']
    let flag = true
    let filesToreturn = []
    files?.forEach((file) => {
        if (!validTypes?.includes(file?.type)) flag = false
        if (validTypes?.includes(file?.type)) filesToreturn.push(file)
    })
    return { flag, newFileList: filesToreturn }
}


export const convertImgToBase64 = (file, setFile = '') => {
    if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
            // Get base64 representation of the image
            const base64String = reader.result.split(',')[1];
            setFile = base64String
        };

        // Read the image file as a data URL
        reader.readAsDataURL(file);
    }
};

export const createBlobUrl = (file) => {
    try {
        const blob = new Blob([file], { type: file.type });
        const blobURL = URL.createObjectURL(blob);
        return blobURL
    } catch (e) {
        Message.error(e)
        return false
    }
}

export const upgradeTime = (setState) => {
    setTimeout(() => {
        setState(prev => prev = { ...prev, show: true })
    }, 10);
}

export const showAddress = (...arr) => {
    return arr?.map((x, i) => <span className={`columns-[${i + 1}] qualification`}>{x}</span>)
}

export function getItem(id, label, key, icon, children, type) {
    return {
        id,
        label,
        key,
        icon,
        children,
        type,
    };
}


export const downloadFile = (file) => {
    if (file) {
        const link = document.createElement('a');
        link.href = file;
        link.download = 'attachment';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}

export const categoriesN400=[
    {
        title:'Introduction',
        value:'Introduction'
    },
    {
        title:'History',
        value:'History'
    },
    {
        title:'Academics',
        value:'Academics'
    },
    {
        title:'State Info',
        value:'State Info'
    },
]


export const asterisk = (text) => {
    return `${text} *`
}
export const timeString = moment("").format('HH:mm:ss'); // HH for 24-hour format, hh for 12-hour format

export const getOccurrences = (dayArray) => {

    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const currentDate = new Date();
    const currentDayNumber = currentDate?.getDate()
    const currentMonth = currentDate.getMonth();
    const year = currentDate.getFullYear();
    const occurrences = [];
    if (dayArray) {
        for (let i = 1; i <= 30; i++) {
            const date = new Date(year, currentMonth, i + (currentDayNumber - 1));
            const dayOfWeek = daysOfWeek[date?.getDay()];
            dayArray?.forEach((dayObj) => {
                if (dayObj.day.toLowerCase() === dayOfWeek.toLowerCase()) {
                    occurrences.push({ date: date.toLocaleString('en-US', { month: 'short', day: 'numeric' }), day: dayOfWeek, data: dayObj });
                }
            });
        }
    }
    return occurrences;
}

function isTimeFormat(str) {
    const dateFormatRegex = /^\d{2}:\d{2}:\d{2}$/;
    if (dateFormatRegex.test(str)) return str
    else return moment(str)?.format("HH:mm:ss")
}

export const generateTimeSlots = (startTime = isTimeFormat("16:00:00"), endTime = isTimeFormat("22:00:00"), slotDuration = 60) => {
    console.log("🚀 ~ file: helpers.js:187 ~ generateTimeSlots ~ startTime, endTime, slotDuration:", startTime, endTime, slotDuration)
    if (startTime && endTime) {
        const slots = [];
        const [startHour, startMinute] = startTime?.split(':');
        const [endHour, endMinute] = endTime?.split(':');
        let currentHour = +startHour;
        let currentMinute = +startMinute;
        while (!(currentHour > +endHour || (currentHour === +endHour && currentMinute >= +endMinute))) {
            const fromTime = `${currentHour?.toString().padStart(2, '0')}:${currentMinute?.toString().padStart(2, '0')}`;
            currentMinute += slotDuration;
            if (currentMinute >= 60) {
                currentHour++;
                currentMinute -= 60;
            }
            const toHour = currentHour;
            const toMinute = currentMinute;
            const toTime = `${toHour?.toString().padStart(2, '0')}:${toMinute?.toString().padStart(2, '0')}`;
            slots?.push({ from: fromTime, to: toTime });
        }
        return slots;
    }
}

export const combinedDateAndTime = (date) => {
    const today = moment();
    const combinedDateTime = moment(date, 'HH:mm:ss').set({
        year: today.year(),
        month: today.month(),
        date: today.date(),
    });
    return combinedDateTime.toISOString()
}

export const addSeconds = (time) => {
    const originalTime = moment(time, 'HH:mm');
    const modifiedTime = originalTime.add(0, 'seconds');
    const formattedResult = modifiedTime.format('HH:mm:ss');
    return formattedResult;
}



export const checkIfInputIsCurrentTime = (startTime, endTime) => {
    const currentDateTime = moment();
    const startDate = moment(startTime);
    const endDate = moment(endTime);
    const isCurrentDateTimeInBetween = currentDateTime?.isBetween(startDate, endDate);
    return isCurrentDateTimeInBetween
}

export const extractTimeWithMeridiem = (timeString) => {
    return moment(timeString).format('hh:mm A')
}
export function convertTimestampToAMPM(timestamp) {
    const formattedTime = moment(timestamp).format('hh:mm A');
    return formattedTime;
}

export function convertTimeToAMPM(timeString) {
    const formattedTime = moment(timeString, 'HH:mm').format('hh:mm A');
    return formattedTime;
}
export const extractDateFromTimeString = (timeString, type = "number") => {
    const parsedDate = moment(timeString);
    let dateTypes = {
        number: "YYYY-MM-DD",
        words: "MMMM DD, YYYY",
        wordsWithDay: "ddd, MMM D, YYYY",
        withoutCommaInWords: "D MMM YYYY"
    }
    const extractedDate = parsedDate.format(dateTypes[type]);
    return extractedDate
}

export const fullDateinWords = (abbreviatedDateString) => {
    const parsedDate = moment(abbreviatedDateString, 'MMM DD');
    const fullDateInWords = parsedDate.format('MMMM DD');
    return fullDateInWords
}

export const momentDateInWords = (date) => {
    return moment(date)?.format("MMMM D, YYYY")
}


export const addMeridiem = (timeString) => {
    const parsedTime = moment(timeString, 'HH:mm:ss');
    const formattedTime = parsedTime.format('hh:mm:ss A');
    return formattedTime
}

export const calculateTotalFee = (arr, itemKey) => {
    return arr?.reduce((sum, item) => sum + stringFeeToInteger(item[itemKey]), 0);
}

// export const addToLanguage = (obj, text) => {
//     if (typeof text === 'string' || text instanceof String) {
//         const key = text.replace(/\s/g, '_');
//         language[key] = text;
//         return language[key]
//     }
//     return console.error("Only string is allowed")
// }

export const getTimeDifference = (date1, date2) => {
    //new date instance
    const start = moment(date1).format("MM-DD-YYYY")
    const end = moment(date2).format("MM-DD-YYYY")
    const dt_date1 = new Date(start);
    const dt_date2 = new Date(end);

    //Get the Timestamp
    const date1_time_stamp = dt_date1.getTime();
    const date2_time_stamp = dt_date2.getTime();

    let calc;

    //Check which timestamp is greater
    if (date1_time_stamp > date2_time_stamp) {
        calc = new Date(date1_time_stamp - date2_time_stamp);
    } else {
        calc = new Date(date2_time_stamp - date1_time_stamp);
    }
    //Retrieve the date, month and year
    const calcFormatTmp = calc.getDate() + '-' + (calc.getMonth() + 1) + '-' + calc.getFullYear();
    //Convert to an array and store
    const calcFormat = calcFormatTmp.split("-");
    //Subtract each member of our array from the default date
    const days_passed = Number(Math.abs(calcFormat[0]) - 1);
    const months_passed = Number(Math.abs(calcFormat[1]) - 1);
    const years_passed = Number(Math.abs(calcFormat[2]) - 1970);

    //Set up custom text
    const yrsTxt = ["year", "years"];
    const mnthsTxt = ["month", "months"];
    const daysTxt = ["day", "days"];

    //Convert to days and sum together
    // const total_days = (years_passed * 365) + (months_passed * 30.417) + days_passed;

    //display result with custom text
    const result = ((years_passed === 1) ? years_passed + ' ' + yrsTxt[0] + ' ' : (years_passed > 1) ?
        years_passed + ' ' + yrsTxt[1] + ' ' : '') +
        ((months_passed === 1) ? months_passed + ' ' + mnthsTxt[0] : (months_passed > 1) ?
            months_passed + ' ' + mnthsTxt[1] + ' ' : '') +
        ((days_passed === 1) ? days_passed + ' ' + daysTxt[0] : (days_passed > 1) ?
            days_passed + ' ' + daysTxt[1] : '');

    //return the result
    return result.trim()
}

export const scrollToTop = () => {
    return window.scrollTo(0, 0)
}


export const formatTimestamp = {
    date: () => { }
}

// Convert Date format to Mon, Aug 19,2022
export function convertDateFormat(dateString) {
    const originalDate = new Date(dateString); // Creating a Date object from the input string

    // Formatting the date into the desired format
    const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        weekday: 'short'
    };

    const formattedDate = originalDate.toLocaleDateString('en-US', options);

    return formattedDate;
}

export const stringFeeToInteger = (str) => {
    const numericString = str?.replace(/\D/g, '');

    const floatValue = parseFloat(numericString);

    return floatValue
}

export const validTypesImages = ['image/png', 'image/jpeg', 'image/JPEG', 'image/PNG', 'image/SVG', 'image/JPG', 'image/jpg', 'image/svg', "image/gif"]

export const validTypesFiles = ['application/msword', 'application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']

export const profilePictureType = ['image/png', 'image/jpeg', 'image/JPEG', 'image/PNG', 'image/JPG', 'image/jpg']

let count = 0
export const attatchmentUploader = (e, setState, type) => {
    let { fileList } = e
    if (fileList?.length > 5) {
        type === 'gallery' ? Message.info("Only 5 images can be uploaded at a time.") : Message.info("Only 5 documents can be uploaded at a time.")
        return
    }
    count = count + 1
    if (count === fileList?.length) {
        const valdiatedImagesBySize = []
        fileList?.forEach(({ originFileObj }) => {
            const fileSize = originFileObj?.size;
            const fileMb = fileSize / 1024 ** 2;
            if (fileMb >= 20) return Message?.success("File size cannot exceed 20 MB")

            if (type === 'gallery' && !validTypesImages?.includes(originFileObj?.type)) return Message.info("Acceptable formats are JPEG, PNG & SVGs only")
            if (type === 'document' && !validTypesFiles?.includes(originFileObj?.type)) return Message.info("Acceptable formats are DOC, DOCX and PDFs only")
            return valdiatedImagesBySize.push(originFileObj)
        })

        setState(prev => { return prev = [...prev, ...valdiatedImagesBySize] })
        count = 0
    }
}


export const FindDate = (date) => {
    return checkNull(moment(date).format("MM-DD-YYYY"))
}




export const formatDateAgo = (date) => {
    date = moment(date)
    const now = moment();
    const diff = now.diff(date, 'seconds');

    if (diff < 60) {
        return diff <= 1 ? 'just now' : `${diff} seconds ago`;
    } else if (diff < 3600) {
        const minutes = Math.floor(diff / 60);
        return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    } else if (diff < 86400) {
        const hours = Math.floor(diff / 3600);
        return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else if (diff < 604800) {
        const days = Math.floor(diff / 86400);
        if (days === 0) {
            return 'today';
        } else if (days === 1) {
            return 'yesterday';
        } else {
            return `${days} day${days > 1 ? 's' : ''} ago`;
        }
    } else {
        // Display in words after one week
        return date.format('MMMM D, YYYY');
    }

};


export const checkConditions = {
    isVaccination: (cond) => {
        return cond === "Vaccination" ? true : false
    },
    isNotVaccination: (cond) => {
        return cond !== "Vaccination" ? true : false
    },
    isConsultation: (cond) => {
        return cond === "Consultation" ? true : false
    },
    isTherapy: (cond) => {
        return cond === "Therapy" ? true : false
    },
}

export const formatSelectedTime = (time, day) => {
    let selectedDay = moment(`${moment().year()} ${day}`, 'YYYY MMM DD')
    const currentMonth = moment().month();
    const selectedMonth = selectedDay.month();

    // Check if the current month is December and the selected month is January
    if (currentMonth === 11 && selectedMonth === 0) {
        selectedDay.add(1, 'year');
    }
    const formattedDay = selectedDay.format('YYYY-MM-DD');
    return `${formattedDay}T${time}:00.000Z`
}

export const addQueryParams = (endpoint, queryParams) => {
    // Check if queryParams is an object and not empty
    if (queryParams && Object.keys(queryParams).length > 0) {
        // Convert queryParams object to a string of key-value pairs
        const queryString = Object.entries(queryParams).map(([key, value]) => { if (value) return `${key}=${value}` }).join('&');

        // Append the query string to the endpoint
        return `${endpoint}?${queryString}`;
    }

    // If no queryParams provided, return the original endpoint
    return endpoint;
}




export function checkUploadedDocument(file) {
    const allowedImageFormats = ['png', 'jpg', 'jpeg', 'JPEF', 'PNG', 'Png', 'JPG', 'Jpg', 'pdf'];
    // const allowedFileFormats = [, 'doc', 'docx', 'xls', 'xlsx'];
    const maxSizeMB = 20;
    // Check file format
    const fileNameParts = file.name.split('.');
    const fileExtension = fileNameParts[1].toLowerCase();
    if (!allowedImageFormats.includes(fileExtension)) {
        Message.error('Only PDF and Images are acceptable ')
        return false;
    }
    // Check file size
    const fileSizeMB = file.size / (1024 * 1024); // Convert bytes to megabytes
    if (fileSizeMB > maxSizeMB) {
        Message.error('Uplaoded report should be should be less then or equal to 20 mb')
        return false
    }
    return true
}

export const dateToTimeStampFormat = (date) => {
    return date ? dayjs(date)?.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : "N/A"
}

// Dates

export const toDayJSObject = (dateString, format = "YYYY-MM-DD") => {
    return dateString ? dayjs(dateString, format) : null
}


// ============