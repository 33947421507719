/* API endpoints ( All API endpoints to be defined here ) */
export const apiRoutes = {
    generic: {
        upload: "/util/upload"
    },
    auth: {
        login: '/auth/login',
        updatePassword: "/auth/update-password"
    },
    announcement: {
        getAll: "/announcement/list",
        create: '/announcement/create',
        getById: '/announcement/'
    },
    notification: {
        getAll: "/admin/notification",
        markAsRead: '/admin/notification/mark-as-read/',
        markAllAsRead: '/admin/notification/mark-all-read',
    },
    course: {
        getAllCourse: "/course/list",
        getCourseById: '/course/',
        getCourseItem: '/course/item/',
        createCourse: '/course/',
        createCourseItem: '/course/item',
        deleteCourse: '/course/delete/',
        deleteCourseItem: '/course/item/delete/',
        updateCourse: '/course/update/',
        updateCourseItem: '/course/item/update/',
        deleteCourseAttachment: "/course/attachment/delete/",
        updateCourseAttachment: "/course/attachment/update/",
        createCourseAttachment: "/course/attachment/add/"

    },
    quiz: {
        getQuizById: '/quiz/course/',
        createQuiz: '/quiz/create-quiz',
        deleteQuiz: "/quiz/delete/quiz/",
        deleteQuestion: "/quiz/delete/question/",
        addQuestion: "/quiz/add/question/",
        updateQuiz: '/quiz/update-question/'

    },
    tutorial: {
        createTutorial: '/util/add-tutorial'
    },
    tests: {
        createTest: "/reading-and-writing/add-test",
        updateTest: "/reading-and-writing/update-test/",
        deleteTest: "/reading-and-writing/delete-test/",
        getTestById: '/reading-and-writing/get-test/',
        getAllTest: "/reading-and-writing/get-tests"
    },
    Users: {
        getAllUsers: "/admin/user-list",
        getKpis: '/admin/dashboard-kpi',
        getPayments: "/admin/user-payments",
        updateProfile:"/user/update-profile",
        updateCoin:"/admin/update-user/"
    },
    immigrationOfficer: {
        getOfficer: "/user/officer/list",
        addImmigrationOfficer: "/admin/add-officer",
        updateImmigrationOfficer:"/admin/update-officer/",
        deleteOfficer:"/admin/delete-officer/"
    },
    N400: {
        getQuestions: '/n400/get/question/list',
        deleteQuestion: "/n400/delete/question/",
        createQuestion: '/n400/add-question',
        updateQuestion: '/n400/update/question/'
    },
    chat: {
        conversations: '/chat/conversations',
        messages: '/chat/admin/message/',
        send_message: '/admin/chat/message'
    }

}