import React from 'react'
import { Layout, Spin } from 'antd';
const { Header, Sider, Content } = Layout;

const ExampleLayout = ({ children, sidebarKey, home }) => {

    // const [collapsed, setCollapsed] = useState(false);

    return (
        <Spin spinning={false} >
            <Layout>
                <Sider trigger={null} collapsible collapsed={false} className='bg-[#f3f2f0]'>
                    {/* Sidebar */}
                    <p className='text-[white]'>Hello world</p>
                </Sider>
                <Layout >
                    <Header >
                        {/* Header Content */}
                    </Header>
                    <Content>
                        {children}
                    </Content>
                </Layout>
            </Layout>
        </Spin>
    )
}

export default ExampleLayout;