import { notification, message as antdMessage } from "antd"

export class Notifications {

    success = (message, duration = 3) => {
        notification.destroy()
        antdMessage.destroy()
        const args = {
            message: 'Success',
            description: message,
            duration,
        };
        notification.success(args)
    }

    error = (message, heading = 'Error') => {
        notification.destroy()
        antdMessage.destroy()
        const args = {
            message: heading,
            description: message,
            duration: 3,
        };
        notification.error(args)
    }

    info = (message, duration = 3) => {
        notification.destroy()
        antdMessage.destroy()
        const args = {
            message: 'Information',
            description: message,
            duration,
        };
        notification.info(args)
    }
}