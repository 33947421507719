import { Controller, useForm } from "react-hook-form"
import ErrorProvider from "./ErrorProvider"
import { Select as AntDSelect, Switch as AntDSwitch, Checkbox as AntDCheckbox, Radio as AntDRadio, AutoComplete as AntDAutoComplete, Space, DatePicker as AntDDatePicker, Input, Upload, TimePicker, TextA, Rate, Form, } from 'antd';
import { Autocomplete as MUIAutocomplete, FormControlLabel, Radio as MUIRadio, RadioGroup as MUIRadioGroup, TextField, Switch as MUISwitch, Checkbox as MUICheckbox } from '@mui/material';
import { CheckProp, checkUploadedFile, dateToTimeStampFormat, debounce, } from "../../Utility";
import { DeleteOutlined, DownOutlined, LoadingOutlined, MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

import { ImageImports } from "../../Assets/Images";
import Image from "../Dynamics/Image";

import dayjs from "dayjs";
import UploadButton from "Components/UploadButton/UploadButton";
import Button from "Components/Dynamics/Button";
const { TextArea } = Input

const defaultFormType = 'antd'

const TextController = ({ defaultValue = null, fieldCss = "w-full", inputDivCss = 'items-start', labelCss = 'w-full', inputType, ...props }) => {
    const { divClassName = 'flex flex-col gap-1', } = props
    const typeToRender = props?.use || props?.native.formType || defaultFormType
    return (
        <Controller
            {...props}
            name={props?.name}
            rules={props?.rules}
            control={props?.control}
            placeholder={props?.placeholder}
            defaultValue={defaultValue}

            render={({ field, fieldState }) => {
                const errorProps = getExternalProps(typeToRender, fieldState, field, props)
                const isError = errorProps?.status;

                const handleKeyDown = (event) => {
                    if (event.key === 'Enter') {
                        event.preventDefault();
                    }
                };
                return (
                    <div className={`${divClassName}`}>

                        {props?.label && <span className={`text-[10px]  text-custom-gray-800 font-semibold  ${labelCss}`}>{props?.label}</span>}
                        <div className={`flex items-center gap-2 w-full`}>
                            <div className="flex flex-col gap-1 w-full ">
                                <Input
                                    {...field}
                                    type={inputType}
                                    placeholder={props?.placeholder}
                                    prefix={props?.icon || false}
                                    onKeyDown={handleKeyDown}
                                    maxLength={props?.maxLength || 250}
                                    showCount={props?.check || false}
                                    onChangeCounter={props?.onChangeCounter}
                                />
                                {errorProps?.status && <span className="text-[10px] text-red-600"> {errorProps?.message}</span>}
                            </div>
                            {props?.cross && <Image src={ImageImports.closeIcon} width={20} onClick={() => props?.crossFunc()} className="cursor-pointer" />}
                        </div>
                    </div>
                )
            }}
        />
    )
}

const PasswordController = ({ defaultValue = null, ...props }) => {
    const typeToRender = props?.use || props?.native.formType || defaultFormType
    return (
        <Controller
            {...props}
            rules={props?.rules}
            name={props?.name}
            control={props?.control}
            defaultValue={defaultValue}
            render={({ field, fieldState }) => {
                const errorProps = getExternalProps('antd', fieldState, field, props)
                return (
                    <div className="flex flex-col gap-1">
                        {props?.label && <span className="text-[10px] text-custom-gray-800 font-semibold">{props?.label}</span>}
                        <div className={`flex flex-col gap-1 w-full ${props?.requiredDev} items-start`}>
                            <Input.Password
                                {...field}
                                placeholder={props?.placeholder}
                                visibilityToggle={props?.visibilityToggle}
                                prefix={props?.icon || false}
                            />
                            {errorProps?.status && <span className={`text-[10px] text-red-600 w-full ${props?.error}`}> {errorProps?.message}</span>}
                        </div>
                        {props?.typography ? <span className="text-[11px] text-custom-gray-400">{props?.typography}</span> : <></>}
                    </div>
                )
            }}
        />
    )
}

const CheckBoxController = ({ defaultValue = false, divClassName = 'flex items-center', label, ...props }) => {


    const CheckBoxControl = {
        mui: MUICheckbox,
        antd: AntDCheckbox
    }
    const typeToRender = props?.use || props?.native.formType || defaultFormType
    const Checkbox = CheckBoxControl[typeToRender]


    return (
        <Controller
            {...props}
            name={props?.name}
            control={props?.control}
            defaultValue={defaultValue}
            render={({ field, fieldState }) => {
                const errorProps = getExternalProps(typeToRender, fieldState)
                const customOnChange = (e, { children } = {}) => {
                    props?.state && props?.state((prev) => ({ ...prev, [props.stateKey]: e }));
                    field?.onChange(e);
                    props?.userOnChange && props.userOnChange(e, children);
                };
                return (
                    <div>
                        <div className={`${divClassName}`}>
                            <FormControlLabel
                                control={<Checkbox   {...field} onChange={customOnChange} checked={field.value} />}
                                label={<span className={props?.labelClassName}>{props?.label}</span>}
                            />
                            {label && <span className="text-[10px] text-custom-gray-800 font-semibold">{label}</span>}
                        </div>
                        {errorProps?.status && <span className="text-[10px] text-red-600"> {errorProps?.message}</span>}
                    </div>
                )
            }}
        />
    )
}


const SelectController = ({ defaultValue = undefined, ...props }) => {
    const { divClassName = 'flex flex-col gap-1 ' } = props
    return (
        <Controller
            {...props}
            name={props?.name}
            control={props?.control}
            defaultValue={defaultValue}
            render={({ field, fieldState }) => {
                const errorProps = getExternalProps('antd', fieldState, field, props)
                const customOnChange = (e, { children } = {}) => {
                    props?.state && props?.state((prev) => ({ ...prev, [props.stateKey]: e }));
                    field?.onChange(e);
                    props?.userOnChange && props.userOnChange(e, children);
                };
                return (
                    <span className={divClassName}>
                        {props?.label && <span className={`text-[10px] text-custom-gray-800 font-semibold ${props?.labelCss}`}>{props?.label}</span>}
                        <div className={`flex flex-col gap-1 w-full ${props?.requiredDev} items-start`}>
                            <div className={`flex items-center gap-3 border border-custom-gray-300 rounded-[6px] w-full  ${props?.mainDivCss}`}>
                                {props?.icon && <Image src={props?.icon} className="ml-[11px]" width={props?.iconWidth || 14} />}
                                <AntDSelect
                                    {...field}
                                    className="flex items-center w-full"
                                    placeholder={props?.placeholder || "Select"}
                                    onChange={customOnChange}
                                    allowClear={props?.allowClear || true}
                                    showSearch
                                    // defaultValue={props?.defaultValue || undefined}
                                    filterOption={(inputValue, option) => option.children ? option.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1 : null}
                                    suffixIcon={<Image src={ImageImports?.arrowDownGray500} width={18} />}
                                >
                                    {props?.options && props?.options?.map(x => <AntDSelect.Option value={x?.value}>{x?.title}</AntDSelect.Option>)}
                                </AntDSelect>
                            </div>
                            {errorProps?.status && <span className={`text-[10px] text-red-600 w-full ${props?.error}`}> {errorProps?.message}</span>}
                        </div>
                    </span >
                )
            }}
        />
    )
}


const RatingInputController = ({ defaultValue = undefined, ...props }) => {


    return (
        <Controller
            {...props}
            name={props?.name}
            control={props?.control}
            defaultValue={defaultValue}
            render={({ field, fieldState }) => {
                const errorProps = getExternalProps('antd', fieldState, field, props)
                return (
                    <span className="flex flex-col gap-1">
                        {props?.label && <span className="text-[10px] text-custom-gray-800 font-semibold">{props?.label}</span>}
                        <div className="flex items-center gap-1 ">
                            <ErrorProvider {...props} {...errorProps}>
                                <Rate allowHalf defaultValue={props?.ratings} disabled={props?.isDisabled} className={props?.className} />
                            </ErrorProvider>
                        </div>
                    </span>
                )
            }}
        />
    )
}



const RatingController = ({ defaultValue = undefined, ...props }) => {


    return (
        <Controller
            {...props}
            name={props?.name}
            control={props?.control}
            defaultValue={defaultValue}
            render={({ field, fieldState }) => {
                const errorProps = getExternalProps('antd', fieldState, field, props)

                const customOnChange = (e) => {
                    props?.state && props?.state((prev) => ({ ...prev, [props.stateKey]: e }))
                    field?.onChange(e)
                }
                return (
                    <span className="flex flex-col gap-1">
                        {props?.label && <span className="text-[10px] text-custom-gray-800 font-semibold">{props?.label}</span>}
                        <div className="flex items-center gap-1 border border-custom-gray-300 rounded-[6px]">
                            {props?.icon ? <Image src={props?.icon} className="ml-[10px]" width={props?.iconWidth || 20} /> : <></>}
                            <ErrorProvider {...props} {...errorProps}>
                                <AntDSelect  {...field} placeholder={props?.placeholder || "Select"} onChange={customOnChange} suffixIcon={<Image src={ImageImports?.arrowDownGray500} width={18} />} >

                                    {props?.ratings?.map((rating) => (
                                        <AntDSelect.Option key={rating} value={rating}>
                                            <Rate value={rating} disabled className="text-xs" allowHalf />
                                        </AntDSelect.Option>
                                    ))}
                                </AntDSelect>
                            </ErrorProvider>
                        </div>
                    </span>
                )
            }}
        />
    )
}







const DropDownController = ({ label = 'label', options = [], defaultValue = null, labelKey = 'label', ...props }) => {


    const AutoCompleteControl = {
        mui: ({ error, ...field }) =>
            <MUIAutocomplete
                {...field}
                options={options}
                getOptionLabel={(option) => option?.[labelKey]}
                onChange={(_, value) => field?.onChange(value)}
                isOptionEqualToValue={option => option}
                renderInput={(params) => <TextField {...params} label={label} error={error} />}
            />,
        antd: ({ error, ...field }) =>
            <AntDAutoComplete
                {...field}
                className={`antd-autocomplete ${field?.className}`}
                style={{ minWidth: "100px", ...field?.style }}
                options={options?.map(option => ({ value: option.value }))}
                placeholder={props?.placeholder}
                suffixIcon={<DownOutlined />}
                filterOption={(inputValue, option) =>
                    option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                }
            />
    }


    const typeToRender = props?.use || props?.native.formType || defaultFormType
    const AutoComplete = AutoCompleteControl[typeToRender]


    return (
        <Controller
            {...props}
            name={props?.name}
            control={props?.control}
            defaultValue={defaultValue}
            render={({ field, fieldState }) => {
                const errorProps = getExternalProps(typeToRender, fieldState, field, props)
                return (
                    <ErrorProvider {...props} {...errorProps}>
                        {AutoComplete(field)}
                    </ErrorProvider>
                )
            }}
        />
    )


}


const RadioGroupController = ({ defaultValue = '', options = [], ...props }) => {


    const RadioControl = {
        mui: (field) =>
            <MUIRadioGroup {...field}>
                {
                    options?.map(({ value, label }, index) => (
                        <FormControlLabel key={index} value={value} control={<MUIRadio />} label={label} />
                    ))
                }
            </MUIRadioGroup>,
        antd: (field) =>
            <div className="flex flex-col">
                {props?.label && <span className="text-[10px] text-custom-gray-800 font-semibold whitespace-nowrap">{props?.label}</span>}
                <AntDRadio.Group    {...field} className={props?.className}>
                    <Space direction={props?.direction || 'vertical'} className="relative w-full">
                        {
                            options?.map(({ value, label, image, head, imageWidth }, index) => (
                                <AntDRadio key={index} value={value} className={`w-[170px] ${image && "flex items-center justify-center radio-btn-cont border border-custom-gray-100 rounded-lg bg-custom-gray-100 w-[180px] h-[180px] "}`}>
                                    <CheckProp prop={image}>
                                        <div className="flex flex-col gap-2 items-center">
                                            <Image src={image} width={imageWidth} />
                                            <span className="text-xs font-semibold">{head}</span>
                                        </div>
                                    </CheckProp>
                                    <div className="flex items-center ">
                                        <CheckProp prop={props?.image}>
                                            <Image src={props?.image} width={props?.imageWidth || 18} className="" />
                                        </CheckProp>
                                        <p className={`${props?.radioLabelStyle}`}>{label}</p>
                                    </div>
                                </AntDRadio>
                            ))
                        }
                    </Space>
                </AntDRadio.Group>
            </div>
    }
    const typeToRender = props?.use || props?.native.formType || defaultFormType
    const RadioToUse = RadioControl[typeToRender]


    return (
        <Controller
            {...props}
            name={props?.name}
            control={props?.control}
            defaultValue={defaultValue}
            render={({ field, fieldState }) => {
                const errorProps = getExternalProps(typeToRender, fieldState)
                return (
                    <div className={props?.className} style={{ border: `${errorProps?.status ? '1px solid red' : 'none'}` }}>
                        <ErrorProvider {...props} {...errorProps}>
                            {RadioToUse(field)}
                        </ErrorProvider>
                    </div>
                )
            }}
        />
    )


}


const SwitchController = ({ defaultValue = false, label, ...props }) => {


    const SwitchControl = {
        mui: MUISwitch,
        antd: AntDSwitch
    }
    const typeToRender = props?.use || props?.native.formType || defaultFormType
    const SwitchToUse = SwitchControl[typeToRender]


    return (
        <Controller
            {...props}
            name={props?.name}
            control={props?.control}
            defaultValue={defaultValue}
            render={({ field, fieldState }) => {
                const errorProps = getExternalProps(typeToRender, fieldState)
                if (props?.defaultChecked) field.value = true
                return (
                    <div className={props?.innerFieldClassName && props?.innerFieldClassName}>
                        <div style={{ border: `${errorProps?.status ? '1px solid red' : 'none'}` }} className="flex flex-col gap-1">
                            {label && <span className="text-[10px] text-custom-gray-800 font-semibold">{label}</span>}
                            <ErrorProvider {...props} {...errorProps}>
                                <FormControlLabel
                                    control={
                                        <AntDSwitch
                                            {...field}
                                            defaultChecked={field?.value}
                                            size="small"
                                            checkedChildren="Yes"
                                            unCheckedChildren="No" />
                                    }
                                />
                            </ErrorProvider>
                        </div>
                        {(field?.value && props?.innerField) &&
                            <div
                                className={props?.innerFieldSpan || "col-span-1"} >
                                {props?.innerField?.map(x => FormMap[x?.type]({ control: props?.control, native: x, ...x }))}
                            </div>}


                    </div>
                )
            }}
        />
    )
}



const DatePickerController = ({ defaultValue = null, ...props }) => {
    const { divClassName = 'flex flex-col gap-1' } = props
    const typeToRender = props?.use || props?.native.formType || defaultFormType
    return (
        <Controller
            {...props}
            name={props?.name}
            defaultValue={defaultValue}
            control={props?.control}
            render={({ field, fieldState }) => {
                const errorProps = getExternalProps(typeToRender, fieldState, field, props)

                const customOnChange = (e) => {
                    props?.state && props?.state((prev) => ({ ...prev, [props.stateKey]: e ? dayjs(e).format("YYYY-MM-DD") : null }))
                    field?.onChange(e)

                }
                return (
                    <div className={`${divClassName}`}>
                        {props?.label && <span className="text-[10px] text-custom-gray-800 font-semibold">{props?.label}</span>}
                        <div className="flex items-center gap-2 ">
                            <ErrorProvider {...props} {...errorProps}>
                                <AntDDatePicker
                                    {...field}
                                    size="small"
                                    picker={props?.picker || "date"}
                                    placeholder={props?.placeholder}
                                    onChange={customOnChange}
                                    allowClear={props?.allowClear || true}
                                />
                            </ErrorProvider>
                            {props?.cross && <Image src={ImageImports.closeGrayRound} width={20} onClick={() => props?.crossFunc()} />}
                        </div>
                    </div>
                    // </div>
                )
            }}
        />
    )
}
const TimePickerController = ({ defaultValue = null, ...props }) => {
    const typeToRender = props?.use || props?.native.formType || defaultFormType


    return <Controller
        {...props}
        name={props?.name}
        control={props?.control}
        defaultValue={defaultValue}
        render={({ field, fieldState }) => {
            const errorProps = getExternalProps(typeToRender, fieldState, field, props)
            return (
                <div className="flex flex-col gap-1">
                    {props?.label && <span className={`text-[10px] text-custom-gray-800 font-semibold ${props?.labelClassName}`}>{props?.label}</span>}
                    <ErrorProvider {...props} {...errorProps}>
                        <TimePicker {...field}
                            placeholder={props?.placeholder}

                        />
                    </ErrorProvider>
                </div>
            )
        }}
    />
}
const CustomController = (props) => {


    return (
        <Controller
            name={props?.name}
            control={props?.control}
            defaultValue={props?.defaultValue}
            rules={props?.rules}
            render={props?.render}
        />
    )


}


const CustomSearchFieldController = ({ defaultValue = '', ...props }) => {


    const typeToRender = props?.use || props?.native.formType || defaultFormType
    return (
        <Controller
            {...props}
            name={props?.name}
            control={props?.control}
            defaultValue={defaultValue}
            render={({ field, fieldState }) => {
                const errorProps = getExternalProps('antd', fieldState, field, props)

                const customOnChange = (e) => {

                    props?.state && debounce(() => props?.state((prev) => ({ ...prev, [props.stateKey]: e.target.value })))()
                    field?.onChange(e)

                }

                return (
                    <div className="flex flex-col gap-1">
                        {props?.label && <span className="text-[10px] text-custom-gray-800 font-semibold">{props?.label}</span>}
                        <ErrorProvider {...props} {...errorProps}>
                            <Input
                                {...field}
                                placeholder={props?.placeholder}
                                prefix={props?.icon || false}
                                onChange={customOnChange} />
                        </ErrorProvider>
                        {props?.typography ? <span className="text-[11px] text-custom-gray-400">{props?.typography}</span> : <></>}
                    </div>
                )
            }}
        />
    )
}


const TextAreaController = ({ defaultValue = null, fieldCss = "w-full   ", ...props }) => {
    const { divClassName = 'flex flex-col gap-1' } = props
    const TextFieldControl = {
        mui: TextField,
        antd: TextArea
    }
    const typeToRender = props?.use || props?.native.formType || defaultFormType
    return (
        <Controller
            {...props}
            name={props?.name}
            control={props?.control}
            placeholder={props?.placeholder}
            defaultValue={defaultValue}
            render={({ field, fieldState }) => {
                props?.onChange && props?.onChange(field?.value)
                const errorProps = getExternalProps(typeToRender, fieldState, field, props)
                return (
                    <div className={`${divClassName}`}>
                        {props?.label && <span className={`text-[10px] text-custom-gray-800 font-semibold ${props?.labelClassName}`}>{props?.label}</span>}
                        <div className={`flex flex-col gap-1 ${fieldCss}`}>
                            <TextArea
                                disabled={props?.disabled || false}
                                rows={props?.rows || 4}
                                maxLength={props?.maxLength || 200}
                                placeholder={props?.placeholder}
                                prefix={props?.icon || false}
                                defaultValue={props?.defaultValue}
                                showCount={props?.check || false}
                                onChangeCounter={props?.onChangeCounter}
                                {...field}
                            />
                            {errorProps?.status && <span className="text-[10px] text-red-600 "> {errorProps?.message}</span>}
                        </div>
                    </div>
                )
            }}
        />
    )
}


const ListController = ({ defaultValue = null, control, native, ...props }) => {


    return <div className={props?.listClassName}>
        {props?.list && props?.list?.map((x, index) =>
            <div key={index} className={x?.span || "col-span-full"}>
                {/* {FormMap[x?.type]({ control: props?.control, native, name: `${props?.name}[${x?.name}]`, ...x })} */}
                {FormMap[x?.type]({ control, native, ...x, name: `${props?.name}[${x.index}].${x?.name}` })}
            </div>
        )}
    </div>
}


const CustomElement = (props) => {
    return props?.element
}

const CustomAudioPlayer = (props) => {
    return <div className="col-span-8 flex justify-between items-center">
        <p className='text-[11px] font-semibold'>{props?.label || 'Add Audio'}</p>
        <div className="flex gap-2 items-center">
            {
                props?.audioUrl ? <audio controls autoplay >
                    <source src={props?.audioUrl} />
                </audio> : props?.disabled && <p className="text-[11px]"> Un Available</p>
            }
            {!props?.disabled &&
                <>
                    {props?.audioUrl ? <Button onClick={() => props?.setaudioUrl('')} buttonType="darkRed">Delete </Button> : <UploadButton fileType={'audio'}
                        setImage={(file) => props?.handleFile(file, 'image')}
                        button={<Button buttonType="lightBlue" >{'Upload Audio'}</Button>} />}
                </>
            }
        </div>
    </div>
}

const getExternalProps = (type, fieldState, field = {}, props) => {
    const errorProps = {
        mui: {
            message: fieldState?.error?.message || 'Required Field ',
            status: !!fieldState?.error,
        },
        antd: {
            message: fieldState?.error?.message || 'Required Field',
            status: !!fieldState?.error,
        }
    }
    if (type == 'mui')
        Object.assign(
            field,
            {
                disabled: props?.disabled || false,
                error: errorProps[type]?.status, className: errorProps[type]?.status ? 'applyError' : '',
                className: errorProps[type]?.status ? props?.className + ' applyError' : (props?.className || ''),
            })
    if (type == 'antd')
        Object.assign(
            field,
            {
                className: errorProps[type]?.status ? props?.className + ' applyError' : (props?.className || ''),
                disabled: props?.disabled || false
            })
    return errorProps[type]
}


export const FormMap = {
    1: TextController,
    2: DropDownController,
    3: DatePickerController,
    4: CheckBoxController,
    5: RadioGroupController,
    6: SwitchController,
    7: PasswordController,
    8: SelectController,
    9: TimePickerController,
    10: CustomSearchFieldController,
    11: TextAreaController,
    12: RatingController,
    13: RatingInputController,
    14: CustomElement,
    15: ListController,
    101: CustomController,
    102: CustomAudioPlayer


}

