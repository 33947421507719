
import { catchAsyncErrors } from 'API/Handlers';
import { postRequest, putRequest } from 'API/api.methods';
import { apiRoutes } from 'API/api.routes';
import { firebaseTokenGenerator } from 'Libraries/firebase/functions';
import { setPageLoader, verification } from 'Redux/Features';
import { pageRoutes } from 'Routing/page.routes';
import { utility } from 'Utility';
import { Message, Storage } from 'Utility/Services';
const login = catchAsyncErrors(async (body) => {
    utility.dispatch(setPageLoader({ page: 'login', flag: true }))
    try {
        const fb_token = await firebaseTokenGenerator()
        let result = await postRequest({
            endPoint: apiRoutes?.auth?.login,
            body: { ...body, firebaseToken: fb_token }
        })
        if (result?.success) {
            Message?.success(result?.Message)
            Storage?.set('user', result?.User)
            Storage?.set('accessToken', result?.authorization)
            utility?.navigate(pageRoutes?.dashboard?.home)
        }
        return result
    } catch (e) {
        Message?.error(e?.message || 'Something went wrong!')
    } finally {
        utility.dispatch(setPageLoader({ page: 'login', flag: false }))

    }

})
const updatePassword = catchAsyncErrors(async (body) => {
    utility.dispatch(setPageLoader({ page: 'updatePassword', flag: true }))
    let result = await putRequest({
        endPoint: apiRoutes?.auth?.updatePassword,
        body: body
    })
    Message?.success(result?.Message)
    utility.dispatch(setPageLoader({ page: 'updatePassword', flag: false }))
    return result
})
export { login, updatePassword }