
import { catchAsyncErrors } from 'API/Handlers';
import { getRequest, postRequest, putRequest } from 'API/api.methods';
import { apiRoutes } from 'API/api.routes';
import { setPageLoader, verification } from 'Redux/Features';
import { pageRoutes } from 'Routing/page.routes';
import { addQueryParams, utility } from 'Utility';
import { Message, Storage } from 'Utility/Services';
const getAllNotifications = catchAsyncErrors(async (query) => {
    utility.dispatch(setPageLoader({ page: 'getAllNotifications', flag: true }))
    let result = await getRequest({
        endPoint: addQueryParams(apiRoutes?.notification?.getAll, query),
    })
    utility.dispatch(setPageLoader({ page: 'getAllNotifications', flag: false }))
    return result
})
const markReadById = catchAsyncErrors(async (id, query) => {
    utility.dispatch(setPageLoader({ page: 'markReadById', flag: true }))
    let result = await putRequest({
        endPoint: addQueryParams(`${apiRoutes?.notification?.markAsRead}${id}`, query),
    })
    Message?.success(result?.Message)
    utility.dispatch(setPageLoader({ page: 'markReadById', flag: false }))
    return result
})
const markAllRead = catchAsyncErrors(async (id, query) => {
    utility.dispatch(setPageLoader({ page: 'markAllRead', flag: true }))
    let result = await putRequest({
        endPoint: addQueryParams(`${apiRoutes?.notification?.markAllAsRead}`, query),
    })
    Message?.success(result?.Message)
    utility.dispatch(setPageLoader({ page: 'markAllRead', flag: false }))
    return result
})
export { getAllNotifications, markReadById, markAllRead }