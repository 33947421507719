import axios from "axios";
import { appConfig } from "../Environments/config";
import { tokenId } from "../Environments/environment";
import { utility } from "../Utility";
import { LocalStorage } from "../Utility/Services";
import { responseHandler } from "./Handlers";
const { apiURL: baseURL } = appConfig;
const Storage = new LocalStorage()
const getToken = (type = 'accessToken', overwrite = false) => overwrite ? overwrite : Storage.get(`${type}`)
const createHeaders = (essentials) => {

    let { extendedHeaders = {}, useFirebase = true, useAuth = true, contentType = 'application/json' } = essentials;

    let initial = { 'content-type': contentType }
    if (useAuth)
        initial.Authorization = getToken('accessToken')
    if (useFirebase)
        initial.firebaseToken = getToken('firebaseToken')

    return {
        ...initial,
        ...extendedHeaders,
    }

}

const postRequest = async (essentials) => {

    let { endPoint = '', body = {} } = essentials;
    let result = await fetch(`${baseURL}${endPoint}`,
        {
            method: 'POST',
            headers: createHeaders(essentials),
            body: JSON.stringify(body),
        });
    return await responseHandler(result)

}

const putRequest = async (essentials) => {

    let { endPoint = '', body = {} } = essentials;
    let result = await fetch(`${baseURL}${endPoint}`,
        {
            method: 'PUT',
            headers: createHeaders(essentials),
            body: JSON.stringify(body)
        });
    return await responseHandler(result)

}

const getRequest = async (essentials) => {

    let { endPoint = '' } = essentials;
    let result = await fetch(`${baseURL}${endPoint}`,
        {
            method: 'GET',
            headers: createHeaders(essentials)
        });
    return await responseHandler(result)

}

const deleteRequest = async (essentials) => {

    let { endPoint = '' } = essentials;
    let result = await fetch(`${baseURL}${endPoint}`,
        {
            method: 'DELETE',
            headers: createHeaders(essentials)
        });
    return await responseHandler(result)

}

const uploadRequest = async (essentials) => {
    let { endPoint = '', body } = essentials;
    let result = await axios.post(`${baseURL}${endPoint}`,
        body,
        {
            headers: createHeaders({ ...essentials, contentType: "multipart/form-data" })
        }
    );
    let { status } = result;
    if (status === 200) return result
    if ([403, 401].includes(status)) {
        Storage.clear()
        utility.navigate('/')
    }
    throw new Error(result.message, { cause: status })
}
export {
    postRequest,
    getRequest,
    putRequest,
    deleteRequest,
    uploadRequest
}