import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { ImageImports } from 'Assets/Images';
import { FormController, FormService } from 'Components/FormControl';
import UploadButton from 'Components/Micro/UploadButton/UploadButton';
import { useForm } from 'react-hook-form';
import Image from 'Components/Dynamics/Image';
import Button from 'Components/Dynamics/Button';
import { DollarCircleFilled } from '@ant-design/icons';
import { Message } from 'Utility/Services';
import { createCourse, updateCourse } from 'API/Services/courses.service';
import Loader from 'Components/Loader/Loader';
import { uploadApi } from 'API/Services/generic.service';
import { validations } from 'Utility/Validations';
const ManageCourseModal = (props) => {
    const { modalTitle, label, open, close, data, fetchCourses } = props
    const [image, setImage] = useState('')
    const [file, setFile] = useState('')
    const Form = useForm()
    const [flag, setFlag] = useState(false)
    const fields = [
        { type: 1, divClassName: 'flex justify-between items-center', divider: false, placeholder: "Enter course title", name: 'name', label: 'Course Title', rules: { required: true, validate: validations?.checkEmptySpace }, use: 'antd', span: "", onChange: (value) => { }, className: "w-[100%] text-[11px] h-[32px]" },
        { type: 1, divClassName: 'flex justify-between items-center', divider: false, placeholder: "Enter course description", name: 'description', label: 'Course Description', rules: { required: true, validate: validations?.checkEmptySpace }, use: 'antd', span: "", onChange: (value) => { }, className: "w-[100%] text-[11px] h-[32px]" },
        {
            type: 4, name: `type`,
            label: <span className='text-xs text-custom-gray-700'>{'Paid Course'} </span>,
            userOnChange: (e, val) => { setFlag(!e?.target?.value); Form?.setValue('fee', '') },
            rules: { required: false }, className: "w-full"
        },
        { type: 10, disabled: !flag, divClassName: 'flex justify-between items-center', divider: false, placeholder: "Enter Fees", name: 'fee', label: 'Fees', rules: { required: false, validate: validations?.check_number }, use: 'antd', className: "w-[100%] text-[11px] h-[32px]", icon: <DollarCircleFilled /> },
    ]
    const handleFile = async (file, type) => {
        const result = await uploadApi([file], 6)
        setImage(result)
    }
    const handleSubmit = async (val) => {
        if (image) {
            const body = {
                name: val?.name,
                type: flag ? 'Paid' : 'Free',
                description: val?.description,
                cover_image: image,
                fee: val?.fee || 0
            }
            if (flag && !val.fee) Message?.error("Fee can't be empty!")
            else {
                if (data?.modalName == 'editCourse') await updateCourse(data?.modalData?.id, body)
                else await createCourse(body)
                Form?.reset()
                close()
                setFlag(false)
                fetchCourses()
            }
        } else { Message?.error('Kindly Upload Course Cover Photo!') }

    }
    useEffect(() => {
        if (data?.modalData) {
            Form?.setValue('name', data?.modalData?.name)
            Form?.setValue('description', data?.modalData?.description)
            setImage(data?.modalData?.cover_image)
            if (data?.modalData?.type !== 'Free') {
                setFlag(true)
                Form?.setValue('type', true)
                Form?.setValue('fee', data?.modalData?.fee)
            } else {
                setFlag(false)
                Form?.setValue('type', false)
            }
        } else { Form?.reset(); setImage('') }
    }, [data])
    return (
        <div className=''>
            <Modal centered width={props?.width || 350} title={
                <div className='flex items-center mt-[0px] gap-3'>
                    <p className='text-[18px]'>{modalTitle}</p>
                </div>} open={open}
                onCancel={() => { Form?.reset(); close() }} footer={null}>
                <p className='font-bold text-xs my-3'>{label}</p>
                <Loader keys={['createCourse', 'updateCourse', 'uploadApi']}>
                    <div className='col-span-8'>
                        <div className='flex justify-between  items-center my-4'>
                            <div className='flex flex-col'>
                                <p className='font-bold text-[11px]'>{'Cover Photo'}</p>
                                <p className='text-custom-gray-500 text-[10px]'>{'This will be set as course cover photo.'}</p>
                            </div>
                            <div className='flex justify-between w-[50%]  items-center mb-[20px]'>
                                {image ? <img src={image || ImageImports?.noProfilePic} className={'rounded-full h-[80px] w-[80px] object-cover'} /> : <div></div>}
                                <div className='flex gap-3  w-'>
                                    {
                                        image &&
                                        <Button onClick={() => setImage('')} additionalClassName='shadow-none text-custom-gray-500 border-none text-[11px] p-0 font-bold'>{'Delete'}</Button>
                                    }
                                    <UploadButton fileType={'image'} setImage={(file) => handleFile(file, 'image')} button={<Button buttonType='lightBlue'>{image ? 'Update' : 'Upload'}</Button>} />
                                </div>
                            </div>
                        </div>
                        <FormController
                            formType={'antd'}
                            Form={Form}
                            onSubmit={handleSubmit}
                            className="flex flex-col gap-5"
                            submitButton={
                                <Button buttonType='blue' htmlType='submit' additionalClassName='font-semibold text-[13px] w-full mt-[20px]'>
                                    {data?.modalName == 'createCourse' ? 'Create Course' : 'Update Course'}
                                </Button>
                            }
                            fields={fields} />
                    </div>
                </Loader>
            </Modal>
        </div >
    );
};
export default ManageCourseModal;