
import { catchAsyncErrors } from 'API/Handlers';
import { deleteRequest, getRequest, postRequest, putRequest } from 'API/api.methods';
import { apiRoutes } from 'API/api.routes';
import { setPageLoader, verification } from 'Redux/Features';
import { pageRoutes } from 'Routing/page.routes';
import { addQueryParams, utility } from 'Utility';
import { Message, Storage } from 'Utility/Services';
const getAllQuestions = catchAsyncErrors(async (query) => {
    utility.dispatch(setPageLoader({ page: 'getAllQuestions', flag: true }))
    let result = await putRequest({
        endPoint: addQueryParams(apiRoutes?.N400?.getQuestions, query),
    })
    utility.dispatch(setPageLoader({ page: 'getAllQuestions', flag: false }))
    return result?.Questions
})
const createN400Question = catchAsyncErrors(async (body) => {
    utility.dispatch(setPageLoader({ page: 'createN400Question', flag: true }))
    let result = await postRequest({
        endPoint: addQueryParams(apiRoutes?.N400?.createQuestion),
        body: body
    })
    Message?.success(result?.Message)
    window.location.reload()
    utility.dispatch(setPageLoader({ page: 'createN400Question', flag: false }))
    return result
})
const updateN400Question = catchAsyncErrors(async (id, body) => {
    utility.dispatch(setPageLoader({ page: 'updateN400Question', flag: true }))
    let result = await putRequest({
        endPoint: apiRoutes?.N400?.updateQuestion + id,
        body: body
    })
    Message?.success(result?.Message)
    window.location.reload()
    utility.dispatch(setPageLoader({ page: 'updateN400Question', flag: false }))
    return result
})
const deleteN400Question = catchAsyncErrors(async (id) => {
    utility.dispatch(setPageLoader({ page: 'deleteN400Question', flag: true }))
    let result = await deleteRequest({
        endPoint: addQueryParams(apiRoutes?.N400?.deleteQuestion + id),
    })
    utility.dispatch(setPageLoader({ page: 'deleteN400Question', flag: false }))
    Message?.success(result?.Message)
    return result?.Questions
})
export { updateN400Question, getAllQuestions, deleteN400Question, createN400Question }