import { utility } from "Utility";
import { Message, Notify } from "../../Utility/Services";
import { clearLoaders } from "Redux/Features";

export const catchAsyncErrors = (Service) => async (...args) => {

    try {
        return await Service(...args)
    }
    catch (exc) {
        let { message, cause: status } = exc;
        console.log({ message, exc })
        // For checking internet connection
        if (!window.navigator.onLine) return Notify.error("Please make sure you have a stable internet connection!", message)
        utility.dispatch(clearLoaders())
        // Notify.error(`Status: ${status || exc.name === 'TypeError' ? 400 : 500}`, message)
        Message?.error(`${message}`)
    }
}