import CustomPagination from 'Components/Dynamics/Custom/CustomPagination'
import React, { useState } from 'react'
const pagination = (WrappedComponent) => {
    return (props) => {
        const { divCss = 'flex flex-col gap-3' } = props
        const [currentPage, setCurrentPage] = useState(1)
        const [pageSize, setpageSize] = useState(10)
        const [total, setTotal] = useState(0)

        const additionalProps = {
            currentPage,
            setCurrentPage,
            total,
            setTotal,
            setpageSize,
        }
        return <div className={`${divCss}`}>
            <WrappedComponent {...props} {...additionalProps} />
            {total > 10 && <CustomPagination
                current={currentPage}
                setCurrentPage={setCurrentPage}
                total={total}
                withTable={false}
                pageSize={pageSize || 10}
            />}
        </div>
    }
}

export default pagination