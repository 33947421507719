import React, { useEffect, useState } from 'react'
import { Header } from '../../../Layout.Components'
import { Divider } from 'antd'
import DynamicTable from '../../../Layout.Components/Custom/DynamicTable'
import { getColumn } from '../../../../Utility/Columns'
import Button from 'Components/Dynamics/Button'
import { FileOutlined, PlusCircleOutlined, SearchOutlined } from '@ant-design/icons'
import CustomModal from 'Components/Dynamics/Modals'
import { Message } from 'Utility/Services'
import { FormController } from 'Components/FormControl'
import { useForm } from 'react-hook-form'
import ManageCourseModal from './ManageCourseModal'
import pagination from 'Components/HighOrderFunctions/Pagination'
import { createCourseAttachment, createCourseItem, deleteCourse, deleteCourseAttachment, deleteCourseItem, getAllCourses, updateCourseAttachment, updateCourseItem } from 'API/Services/courses.service'
import Loader from 'Components/Loader/Loader'
import { ImageImports } from 'Assets/Images'
import UploadButton from 'Components/Micro/UploadButton/UploadButton'
import { uploadApi } from 'API/Services/generic.service'
import { downloadFile } from 'Utility'

const CourseListing = pagination((props) => {
  const [documentImage, setDocumentImage] = useState('')
  const { currentPage, setTotal, setCurrentPage } = props
  const Form = useForm()
  const Form2 = useForm()
  const Form3 = useForm()
  const [data, setData] = useState([])
  const [courseId, setCourseId] = useState()
  const [queryFilters, setQueryFilters] = useState({
    name: null,
    type: null,
    date: null
  })
  const fields = [
    { type: 1, name: 'order', label: 'Order of Video', labelCss: 'font-normal text-custom-gray-500 mb-1 ', rules: { required: true }, placeholder: 'Enter the order of video', span: 'col-span-12', use: 'antd', className: "text-xs h-12" },
    { type: 1, name: 'name', label: 'Name', rules: { required: true, }, labelCss: 'font-normal text-custom-gray-500 mb-1', placeholder: 'Enter Video Name', span: 'col-span-12', use: 'antd', className: "text-xs h-12 " },
    { type: 1, name: 'video_url', label: 'Video Link', labelCss: 'font-normal text-custom-gray-500 mb-1 ', rules: { required: true }, placeholder: 'Enter Video Link', span: 'col-span-12', use: 'antd', className: "text-xs h-12" },
    { type: 1, name: 'duration', label: 'Duration', labelCss: 'font-normal text-custom-gray-500 mb-1 ', rules: { required: true }, placeholder: 'Enter Video Duration', span: 'col-span-12', use: 'antd', className: "text-xs h-12" },
  ]
  const fields2 = [
    { type: 10, name: 'search', state: setQueryFilters, stateKey: "name", userOnChange: () => { setCurrentPage(1) }, rules: { required: false }, label: 'Search', placeholder: 'Search by course name', use: 'antd', span: "col-span-3", className: "h-[40px]", icon: <SearchOutlined /> },
    { type: 8, name: 'courseType', label: "", state: setQueryFilters, stateKey: "type", rules: { required: false }, label: "Course type", placeholder: 'Search by course type', use: 'antd', span: "col-span-4", className: "w-full", size: "small", mainDivCss: "bg-white h-[40px]", options: [{ value: "paid", title: "Paid" }, { value: "free", title: "Free" }] },
    { type: 3, name: 'date', label: "", state: setQueryFilters, stateKey: "date", rules: { required: false }, label: "Filter by date", placeholder: "Select date", use: 'antd', span: "col-span-4", className: "h-[40px] w-full" },
  ]
  const DocumentField = [
    { type: 1, name: 'title', label: 'Document Title', labelCss: 'font-normal text-custom-gray-500 mb-1 ', rules: { required: true }, placeholder: 'Enter the document title', span: 'col-span-12', use: 'antd', className: "text-xs h-12" },

  ]
  const [modalData, setModalData] = useState({
    modalName: "",
    modalData: {}
  })
  console.log({ modalData })
  const [modal, setModal] = useState({
    add: false,
    delete: false,
    addVideo: false,
    deleteVideo: false,
    manageMaterial: false,
    manageDocument: false,
    addDocument: false
  })
  const handleModal = (name, modalName = false, data = false) => {
    setModal((prev) => { return { ...prev, [name]: !modal[name] } })
    setModalData({ modalName: modalName, modalData: data })
  }
  const deleteFunc = async () => {
    if (modalData?.modalName == 'deleteVideo') await deleteCourseItem(modalData?.modalData?.id)
    else if (modalData?.modalName == 'deleteDocument') { await deleteCourseAttachment(modalData?.modalData?.id) }
    else await deleteCourse(modalData?.modalData?.id)
    fetchCourses()
    handleModal('delete')
  }
  const fetchCourses = async () => {
    const result = await getAllCourses(queryFilters)
    setData(result)
  }
  const handleCourseItem = async (val) => {
    if (!modalData?.modalName) await createCourseItem({ ...val, course: courseId })
    else await updateCourseItem(modalData?.modalData?.id, val)
    handleModal('addVideo');
    fetchCourses()
  }
  const handleDocuments = async (val) => {
    if (!documentImage) return Message?.error('Kindly upload Document!')
    if (modalData?.modalName == 'addDocument') {
      const result = await createCourseAttachment(modalData?.modalData?.id, {
        ...val,
        url: documentImage,
      })
      if (result?.success)
        handleModal('addDocument')

    }
    else {
      const result = await updateCourseAttachment(modalData?.modalData?.id, {
        ...val,
        url: documentImage,
      })
      if (result?.success) handleModal('addDocument')
    }
    fetchCourses()

  }
  const handleFile = async (file, type) => {
    const result = await uploadApi([file], 6)
    setDocumentImage(result)
  }
  useEffect(() => {
    fetchCourses()
  }, [queryFilters?.date, queryFilters?.name, queryFilters?.type])
  useEffect(() => {
    if (modalData?.modalName == 'editVideo') {
      Form2?.setValue('name', modalData?.modalData?.name)
      Form2?.setValue('video_url', modalData?.modalData?.video_url)
      Form2?.setValue('duration', modalData?.modalData?.duration)
      Form2?.setValue('order', modalData?.modalData?.order)
    }
    else if (modalData?.modalName == 'editDocument') {
      Form3?.setValue('title', modalData?.modalData?.title)
      setDocumentImage(modalData?.modalData?.url)
    } else {
      Form2?.setValue('name', '')
      Form2?.setValue('video_url', '')
      Form2?.setValue('duration', '')
      Form2?.setValue('order', '')
      Form3?.setValue('title',)
      setDocumentImage()
    }
  }, [modalData?.modalData])
  return (
    <div className='bg-white p-3 rounded-md'>
      <div>
        <div className='flex justify-between items-center'>
          <Header heading={'Course Listing'} />
          <Button onClick={() => { handleModal('add', 'createCourse') }} buttonType='blue'><PlusCircleOutlined />Create new course</Button>
        </div>
        <FormController
          Form={Form}
          fields={fields2}
          onSubmit={() => { }}
          className={'grid grid-cols-12 gap-2'}
        />
        <Divider className='my-2' />
      </div>
      <Loader keys={['getAllCourses']}>
        <DynamicTable column={getColumn('courseListing', handleModal, setCourseId)} pagination={false} data={data}
          className={`border border-custom-gray-200  rounded-md mt-3`} />
      </Loader>
      {/* Add , Edit Course */}
      <ManageCourseModal
        fetchCourses={fetchCourses}
        data={modalData}
        width={500}
        showTitleIcon={false}
        modalTitle={modalData?.modalName == 'createCourse' ? 'Create Course' : 'Edit Course'}
        open={modal?.add} close={() => handleModal('add')}
      />
      {/* Delete */}
      <CustomModal
        stateReason={modalData?.modalName == 'deleteVideo' ? 'Are you sure, you want to delete this Video?' : modalData?.modalName == 'deleteDocument' ? 'Are you sure , you want to delete this document?' : 'Are you sure, you want to delete this Course?'}
        modalTitle='Delete'
        open={modal?.delete}
        close={() => handleModal('delete')}
        field={<div className='w-full mt-5'>
          <Button onClick={deleteFunc} additionalClassName='w-full' buttonType='darkRed'>
            Delete
          </Button></div>}
      />
      {/* Add , Edit Videos */}
      <CustomModal
        showStateReason={false}
        width={400}
        modalTitle={modalData?.modalName == 'editVideo' ? 'Edit Video' : 'Add Video'}
        showTitleIcon={false}
        open={modal?.addVideo}
        close={() => { handleModal('addVideo'); Form2?.reset() }}
        field={<div>
          <FormController
            Form={Form2}
            fields={fields}
            onSubmit={handleCourseItem}
            className={'flex w-full flex-col gap-5'}
            submitButton={<Button htmlType='submit' additionalClassName='font-semibold w-full h-[45px] text-[14px] rounded-[10px] shadow-xl' buttonType='blue'>{modalData?.modalName == 'editVideo' ? 'Update ' : 'Submit'}</Button>}
          />
        </div>}
      />
      {/* Add , Edit Course Document */}
      <CustomModal
        showStateReason={false}
        width={500}
        modalTitle={modalData?.modalName == 'editDocument' ? 'Edit Document' : 'Add Document'}
        showTitleIcon={false}
        open={modal?.addDocument}
        close={() => { handleModal('addDocument'); Form2?.reset() }}
        field={<div>
          <div className='flex justify-between  items-center my-4'>
            <div className='flex flex-col'>
              <p className='font-bold text-[11px]'>{'Document'}</p>
            </div>
            <div className='flex justify-between w-[50%]  items-center mb-[20px]'>
              {documentImage ? <Button onClick={() => { downloadFile(documentImage) }}><FileOutlined /> Document</Button> : <div></div>}
              <div className='flex gap-3  w-'>
                {
                  documentImage &&
                  <Button onClick={() => setDocumentImage('')} additionalClassName='shadow-none text-custom-gray-500 border-none text-[11px] p-0 font-bold'>{'Delete'}</Button>
                }
                <UploadButton fileType={'file'} setImage={(file) => handleFile(file)} button={<Button buttonType='lightBlue'>{documentImage ? 'Update' : 'Upload'}</Button>} />
              </div>
            </div>
          </div>
          <FormController
            Form={Form3}
            fields={DocumentField}
            onSubmit={handleDocuments}
            className={'flex w-full flex-col gap-5'}
            submitButton={<Button htmlType='submit' additionalClassName='font-semibold w-full h-[45px] text-[14px] rounded-[10px] shadow-xl' buttonType='blue'>{modalData?.modalName == 'editVideo' ? 'Update ' : 'Submit'}</Button>}
          />
        </div>}
      />
      {/* Manage Course Videos. */}
      <CustomModal
        showStateReason={false}
        width={1000}
        modalTitle='Manage Course Videos'
        showTitleIcon={false}
        open={modal?.manageMaterial}
        close={() => handleModal('manageMaterial')}
        field={<div>
          <div className='flex justify-end'><Button onClick={() => {
            handleModal('manageMaterial', 'addVideo', modalData?.modalData);
            handleModal('addVideo')
          }} buttonType='blue'>
            <PlusCircleOutlined />Add New Video</Button>
          </div>
          <DynamicTable column={getColumn('courseMaterial', handleModal)} pagination={false} data={modalData?.modalName == 'manageMaterial' ? modalData?.modalData?.Course_Items : []}
            className={`border border-custom-gray-200  rounded-md mt-3`} />
        </div>}
      />
      {/* Manage Course Document */}
      <CustomModal
        showStateReason={false}
        width={900}
        modalTitle='Manage Course Documents'
        showTitleIcon={false}
        open={modal?.manageDocument}
        close={() => handleModal('manageDocument')}
        field={<div>
          <div className='flex justify-end'><Button onClick={() => {
            console.log(modalData)
            handleModal('manageDocument', 'addDocument', modalData?.modalData);
            handleModal('addDocument', 'addDocument', modalData?.modalData)
          }} buttonType='blue'>
            <PlusCircleOutlined />Add New Document</Button>
          </div>
          <DynamicTable column={getColumn('courseDocuments', handleModal)} pagination={false} data={modalData?.modalName == 'manageDocument' ? modalData?.modalData?.attachments : []}
            className={`border border-custom-gray-200  rounded-md mt-3`} />
        </div>}
      />
    </div>
  )
})

export default CourseListing