import Button from 'Components/Dynamics/Button'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { FormController } from 'Components/FormControl'
import MCQS from './N400Test/Mcqs'
import QuestionTest from './N400Test/QuestionTest'
import MCQSWithImage from './N400Test/McqsWithImage'
import { Header } from 'Components/Layout.Components'
import { selectQuestionType } from 'Utility'
import CheckEmpty from 'Components/Empty'
import CustomModal from 'Components/Dynamics/Modals'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { deleteN400Question, getAllQuestions } from 'API/Services/n400.service'
import Loader from 'Components/Loader/Loader'
import CreateShortQuestion from './N400Test/CreateShortQuestion'
import CreateMcqQuestion from './N400Test/CreateMcqQuestion'

const N400Form = () => {
    const [modal, setModal] = useState({
        question: false,
        delete: false,
        manageQuestion: false
    })
    const [id, setId] = useState(false)
    const [formItems, setFormItems] = useState([])
    const Form = useForm()
    const Form2 = useForm()
    const [questionType, setQuestionType] = useState(false)
    const [editQuestionData, setEditQuestionData] = useState(false)
    const questionField = [{ type: 8, options: selectQuestionType, name: `questionType`, label: 'Select Question', rules: { required: true }, placeholder: 'Select question', use: 'antd', span: "col-span-12", },]
    const renderQuestions = (type, count, item) => {
        const questionTypes = {
            'MCQ': <MCQS form={Form} count={count} data={item} />,
            'TEXT': <QuestionTest form={Form} count={count} data={item} />,
        }
        return questionTypes[type]
    }
    const handleModal = (type) => {
        setModal(((prev) => { return { ...prev, [type]: !modal[type] } }))
    }
    const getN400Questions = async () => {
        const result = await getAllQuestions()
        setFormItems(result)
    }
    const deleteQuestion = async () => {
        await deleteN400Question(id)
        window?.location?.reload()
        handleModal('delete')
        getN400Questions()
    }
    useEffect(() => {
        getN400Questions()
    }, [])
    return (
        <Loader keys={['createOst', 'getAllQuestions', 'deleteQuestion']}>

            <form onSubmit={() => { }}>
                <Header heading={'N400 Test'} />
                <div className='grid grid-cols-12 justify-center my-[40px]'>
                    <div className='col-span-6 p-2   col-start-4'>
                        <CheckEmpty arr={formItems?.filter(item => !Array.isArray(item))?.length} >
                            {
                                formItems?.map((item, index) => (
                                    item?.length !== 0 && <div className='flex gap-1 justify-between mb-4'>
                                        {renderQuestions(item?.type, index, item)}
                                        <div className='flex gap-4 ml-[20px]'>
                                            <Button additionalClassName='border-none shadow-none mt-[-5px] p-0' onClick={() => { setQuestionType(item?.type); handleModal('manageQuestion'); setEditQuestionData(item) }}><EditOutlined className='gray text-[16px]' /></Button>
                                            <Button additionalClassName='border-none shadow-none mt-[-5px] p-0' onClick={() => { setId(item?.id); handleModal('delete') }}><DeleteOutlined className='red text-[16px] text-[red]' /></Button>
                                        </div>
                                    </div>))
                            }
                        </CheckEmpty>
                        <Button onClick={() => handleModal('question')} additionalClassName={'w-full  font-semibold text-[13px]  shadow-md bg-[white]'} ><PlusOutlined /> {'Add Question'}</Button>
                    </div>
                </div>
            </form>
            {/* Select Question Type Modal */}
            <CustomModal
                showTitleIcon={false}
                open={modal?.question}
                close={() => handleModal('question')}
                titleHead={'Question'}
                stateReason={'Kindly Select Question u want to create'}
                field={<FormController
                    onSubmit={(val) => {
                        console.log("🚀 ~ N400Form ~ val:", val)
                        setQuestionType(val?.questionType)
                        setEditQuestionData(false)
                        handleModal('question')
                        handleModal('manageQuestion')
                        Form2?.reset()
                    }}
                    fields={questionField}
                    Form={Form2}
                    className={'grid grid-cols-12 gap-4 my-4'}
                    submitButton={<Button additionalClassName={'w-full col-span-12'} buttonType='blue' htmlType='submit'>{'Submit'}</Button>}
                />} />
            {/* Delete Modal */}
            <CustomModal
                stateReason={'Are you sure, you want to delete this?'}
                modalTitle='Delete'
                open={modal?.delete}
                close={() => handleModal('delete')}
                field={<div className='w-full mt-5'>
                    <Button onClick={deleteQuestion} additionalClassName='w-full' buttonType='darkRed'>
                        Delete
                    </Button></div>}
            />
            {/* Create Question Modal */}
            <CustomModal
                width={600}
                showTitleIcon={false}
                open={modal?.manageQuestion}
                close={() => handleModal('manageQuestion')}
                titleHead={'Question'}
                stateReason={'N400 Fields'}
                field={<div>
                    {questionType == 'TEXT' ? <CreateShortQuestion data={editQuestionData} close={() => handleModal('manageQuestion')} getN400Questions={getN400Questions} />
                        :
                        <CreateMcqQuestion data={editQuestionData} close={() => handleModal('manageQuestion')} getN400Questions={getN400Questions} />}
                </div>} />
        </Loader>
    )
}

export default N400Form